import { ActionIcon, Box, Button, Divider, Grid, Group, Paper, Text, UnstyledButton } from '@mantine/core';
import React, { useEffect } from 'react';
import { FaArrowLeft, FaPlus } from 'react-icons/fa';
import SimpleHeader from '../components/simple_header';
import { useAuth } from '../contexts/auth.context';
import ProfilePage from './profile';
import OverviewPage from './overview';
import FilesPage from './files';
import PreferencesPage from './preferences';
import UsersPage from './users';
import FieldsPage from './fields';
import IntegrationsPage from './integrations';
import WhitelabelPage from './whitelabel';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApp } from '../contexts/app.context';
import { useMediaQuery } from '@mantine/hooks';
import BillingPage from './billing';
import AutomationsPage from './automations';
import { notifications } from '@mantine/notifications';
// import { Channels, Flows } from '../components';

export default function SettingsPage(){
    const {role, planData} = useAuth();
    const {startPlan} = useApp();

    const isMobile = useMediaQuery('(max-width: 900px)');

    const [selected, setSelected] = React.useState(isMobile ? null : "profile");
    const [openUsers, setOpenUsers] = React.useState(false);
    const [openTeams, setOpenTeams] = React.useState(false);

    const { hash } = useLocation();
    const navigate = useNavigate();

    const options = [
        {key: "profile", title: "Profile", allowed: true },
        ["Owner", "Admin"].includes(role?.profile?.title) && {key: "automations", title: "Automations", allowed: true },
        ["Owner", "Admin"].includes(role?.profile?.title) && {key: "users", title: "Manage Team", allowed: true },
        ["Owner", "Admin"].includes(role?.profile?.title) && {key: "billing", title: "Billing", allowed: true },
        ["Owner", "Admin"].includes(role?.profile?.title) && {key: "brand", title: "Brand Settings", allowed: planData?.active },
        ["Owner", "Admin"].includes(role?.profile?.title) && {key: "integrations", title: "Integrations", allowed: planData?.active },
        ["Owner", "Admin"].includes(role?.profile?.title) && {key: "whitelabel", title: "Whitelabel Client Portal", allowed: planData?.active },
        
        // role && {key: "files", title: "Arquivos" },
        // role && {key: "preferences", title: "Preferências" },
        // role && {key: "users", title: "Usuários", buttons: [
        //     { leftSection: <FaPlus />, title: "Usuário", onClick: () => {setOpenUsers(true)} }
        // ]},
        // role && {key: "teams", title: "Equipes", buttons: [
        //     { leftSection: <FaPlus />, title: "Equipe", onClick: () => {setOpenTeams(true)} }
        // ]},
        // role && {key: "financeiro", title: "Financeiro" },
    ].filter(nn => nn)

    React.useEffect(() => {
        if (hash) {
            setSelected(hash.split("#")[1]);
        }else{
            setSelected(isMobile ? null : "profile");
        };
      }, [hash, isMobile]);
    
    React.useEffect(() => {
        if (selected && !options.find(opt => opt.key === selected)) {
            setSelected("profile");
        }
    }, [selected, isMobile]);

    return <>
        <Paper shadow='xs' style={{padding: 0, background: '#FFFFFF',}}>
            <Grid>
                {(!isMobile || !selected) && <Grid.Col span={{ base: 12, md: 4 }} style={{minHeight: '70vh'}}>
                    <Box style={{height: '100%', background: 'white', borderRight: '1px solid #DFDFDF'}}>
                        {options.map(({key, title, allowed}) => (
                            <UnstyledButton style={{width: '100%'}} onClick={() => {
                                if(!allowed) notifications.show({
                                    title: "Payment required",
                                    message: "Check for your billing settings, there is one payment subscription that failed, change your payment method and try again.",
                                    color: "yellow"
                                })
                                navigate(`/settings#${key}`);
                            }}>
                                <Group
                                    style={{borderLeft: `4px solid ${selected === key ? "black" : "transparent"}`}}
                                    p="xl"
                                >
                                    <Box style={{flex: 1}}>
                                        <Text size="md" fw="bold">{title}</Text>
                                    </Box>
                                </Group>
                                <Divider />
                            </UnstyledButton>
                        ))}
                    </Box>
                </Grid.Col>}
                {(!isMobile || selected) && <Grid.Col span={{ base: 12, md: 8 }} style={{height: '100%'}}>
                    {isMobile && <Group p="md">
                        <ActionIcon size="xl" onClick={() => navigate("/settings")}><FaArrowLeft /></ActionIcon>
                    </Group>}
                    <Box p="md" pr="xl">
                        {selected === "profile" && <ProfilePage />}
                        {selected === "brand" && <ProfilePage company />}
                        {selected === "automations" && <AutomationsPage />}
                        {selected === "overview" && <OverviewPage />}
                        {selected === "fields" && <FieldsPage />}
                        {selected === "billing" && <BillingPage />}
                        {selected === "preferences" && <PreferencesPage />}
                        {selected === "users" && <UsersPage />}
                        {selected === "files" && <FilesPage />}
                        {selected === "integrations" && <IntegrationsPage />}
                        {selected === "whitelabel" && <WhitelabelPage />}
                    </Box>
                </Grid.Col>}
            </Grid>
            </Paper>

    </>
    
}
