import { api } from "./api"

export const GET_ALL_SHARE_LINKS = async () => {
    const {data} = await api.get("/share_links")
    return data || [];
}

export const DELETE_SHARE_LINK = async (id) => {
    const {data} = await api.delete(`/share_links/${id}`)
    return data;
}

export const SAVE_SHARE_LINK = async (params : any) => {
    const {data} = await api.post(`/share_links`, params)
    return data;
}
