import { UnstyledButton } from '@mantine/core';
import React, { useRef } from 'react';

const FilePicker = ({ onSelect, children }) => {
  const fileInputRef = useRef<any>(null);

  const openFilePicker = () => {
    fileInputRef.current.click();
  };

  const handleFileSelected = (event) => {
    const selectedFiles = Array.from(event.target.files);
    onSelect(selectedFiles);
  };

  return (
    <div style={{display: 'flex'}}>
        <UnstyledButton onClick={openFilePicker}>
            {children}
        </UnstyledButton>
        <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileSelected}
            multiple
        />
    </div>
  );
};

export default FilePicker;
