import { ActionIcon, Box, Button, Group, Paper, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React from 'react'
import { useApp } from '../../contexts/app.context';
import { EXPORT_COMPANIES, GET_ALL_COMPANIES } from '../../services/companies';
import Table from '../table';
import { FaCheck, FaSign, FaSignInAlt } from 'react-icons/fa';
import { Buffer } from 'buffer';
import { extenseRecurrenceType, getExtenseDatetime } from '../../utility/util';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth.context';
import moment from 'moment-timezone';

interface ListProps{}
interface ListHandle{
    refresh: () => void;
}

const List : React.ForwardRefRenderFunction<ListHandle, ListProps> = ({}, ref) => {
    const [loading, setLoading] = React.useState(false);
    const [loadingExport, setLoadingExport] = React.useState(false);
    const [error, setError] = React.useState<any>(null);
    const [data, setData] = React.useState([]);
    const [months, setMonths] = React.useState([]);
    const [typeView, setTypeView] = React.useState<string>("general");

    const { onChangeRole } = useAuth();
    const navigate = useNavigate();

    const COLUMNS = typeView === "payments"
    ? [
        {title: "Company", key: 'name', render: (item) => <Box>
            <Text size="sm">{item.name}</Text>
        </Box>},
        ...months.map(month => ({
            title: moment(month.base).format("MMMM/YYYY"),
            key: `${month.base}`,
            render: (item) => ((item.plan_payments ?? [])
                .filter(p => (
                    p.payed_at && 
                    moment(p.payed_at).isBetween(
                        moment(month.base).startOf("month"),
                        moment(month.base).endOf("month")
                    )
                ))
                .reduce((pv, p) => pv + p.value,0)
            ).toFixed(2),
        }))
    ]
    : [
        {title: "Company", key: 'name', render: (item) => <Box>
            <Text size="sm">{item.name}</Text>
            <Text size="sm">{item.email}</Text>
            <Text size="sm">{`+${item.phone_code ?? ""}${item.phone}`}</Text>
        </Box>},
        {title: "Account", key: 'active_payment_method', render: (item) => <Box>
            <Text size="sm">Users: {(item.users || []).filter(u => u.roles && u.roles.length > 0 && !(u.roles || []).some(r => ["Client"].includes(r.profile?.title))).length}</Text>
            <Text size="sm">Clients: {(item.users || []).filter(u => u.roles && u.roles.length > 0 && (u.roles || []).some(r => ["Client"].includes(r.profile?.title))).length}</Text>
            <Text size="sm">Plan: {(item.seats || [])[0]?.plan?.service?.plan_name || "Free"} - {extenseRecurrenceType((item.seats || [])[0]?.plan?.service?.recurrence_type)}</Text>
            <Text size="sm">{(item.seats || [])[0]?.plan?.service?.plan_type !== "one-time" && item.active_payment_method && `Next payment: ${getExtenseDatetime((item.seats || [])[0]?.active_until, false)}`}</Text>
        </Box> },
        {title: "Actions", key: 'actions', render: (item) => {
            let roles = (item.users || []).reduce((pv, u) => pv.concat(u.roles), []);
            let role = roles.find(r => ["Owner", "Admin"].includes(r.profile?.title))
            return role ? <Box>
                <ActionIcon
                    onClick={() => {
                        navigate("/")
                        onChangeRole(role._id)
                    }}
                    variant='outline'
                >
                    <FaSignInAlt />
                </ActionIcon>
            </Box> : null
        }},
    ]

    const handleExport = () => {
        setLoadingExport(false);
        EXPORT_COMPANIES()
        .then(res => {
            setLoadingExport(false);
            for(let file of res.files ?? []){
                if(file.url) window.open(file.url, "_blank");
                if(file.base64){
                    const byteCharacters = Buffer.from(file.base64, "base64");
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters[i];
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                
                    const blob = new Blob([byteArray], { type: file.contentType });

                    const url = URL.createObjectURL(blob);;
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `${file.filename}`;
                    a.click();
                    URL.revokeObjectURL(url);
                }
            }
        })
        .catch(err => {
            setLoadingExport(false);
            notifications.show({ message: err.message, color: 'red' })
        })
    }

    const loadCompanies = () => {
        setError(null);
        setLoading(true);

        GET_ALL_COMPANIES()
        .then(companies => {
            setLoading(false);
            setData(companies);
        })
        .catch(err => {
            setLoading(false);
            setError(err.message);
            notifications.show({message: err.message, color: 'red'});
        })
    }

    const refresh = () => { 
        loadCompanies();
    }

    React.useEffect(refresh, []);
    
    React.useEffect(() => {
        let m = moment("2023-12-01T00:00:00");
        let ms = [];
        while(m < moment().endOf("month")){
            ms.push({ base: m.format() })
            m.add(1, "months");
        }
        setMonths(ms);
    }, []);

    React.useImperativeHandle(ref, () => ({
        refresh: () => { refresh(); }
    }));

    return <>
        <Paper p="sm" mt="md">
            <Group>
                <Button loading={loadingExport} variant='outline' onClick={() => setTypeView(p => p === "payments" ? "general" : "payments")}>
                    {typeView === "payments" ? "General View" : "Billing View"}
                </Button>
                <Button loading={loadingExport} onClick={handleExport}>Export</Button>
            </Group>
            <Table 
                data={data}
                columns={COLUMNS}
                error={error}
                loading={loading}
                actions={[
                    // {
                    //     title: "Deletar", 
                    //     color: "red", 
                    //     loading: item => Boolean(loadingDelete[item.id]), 
                    //     onClick: ({item}) => {
                    //         app.confirmDialog(
                    //             {text: "Realmente deseja deletar esse usuário?"}, 
                    //             (({confirmed}) => {
                    //                 if(confirmed) deleteUser(item.id);
                    //             })
                    //         )
                    //     }
                    // }
                ]}
            />
        </Paper>
    </>
}

export default React.forwardRef(List);