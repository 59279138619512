import { Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import {
    Chart as ChartJS,
    registerables
} from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Chart, } from 'react-chartjs-2';
import { GET_REVENUE } from '../../services/statistics';
import { CHART_COLORS, months, transparentize } from '../../utility/chart_utils';
import moment from 'moment';
  
ChartJS.register(
    ...registerables
);
  
const RevenueChart = ({ }) => {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true)
        GET_REVENUE({  })
        .then(res => {
            setData(res);
            setLoading(false)
        })
        .catch(err => {
            setLoading(false);
            notifications.show({ message: err.message, color: "red" })
        })
    }, [])
    
    if(loading) return <div style={{textAlign: 'center'}}><Loader /></div>
    return <Chart
        type="bar"
        options={{
            scales: {
                yAxes: {
                    display: true,
                    beginAtZero: true,
                    suggestedMax: data.length > 0 ? undefined : 50000
                }
            },
            plugins: {
                legend: { display: false },
                filler: {
                    propagate: true
                }
            }
        }}
        data={{
            labels: data.length > 0 ? data.map(d => d.label) : [moment.months(moment().month()), moment.months(moment().add(1, "month").month())],
            datasets: [
                {
                    // tension: 0.4,
                    // pointRadius: 5,
                    data: data.length > 0 ? data.map(d => d.value) : [0, 0],
                    borderColor: CHART_COLORS.green,
                    // fill: true,
                    backgroundColor: transparentize(CHART_COLORS.green, 0.5),
                }
            ]
          }}
    />
}

export default RevenueChart;