import * as React from "react";
import {
  FaBook,
  FaChartLine,
  FaDashcube,
  FaHome,
  FaLock,
  FaPeopleArrows,
  FaPhone,
  FaRegFileWord,
  FaSign,
  FaSlidersH,
  FaSlideshare,
  FaThList,
  FaWrench
} from "react-icons/fa";
import { default as HomePage } from "../pages/home";
import InvitePage from "../pages/invite";
import ValidatePage from "../pages/validate";
import LoginPage from "../pages/login";
import PolicyPage from "../pages/policy";
import RecoverPage from "../pages/recover";
import SignupPage from "../pages/signup";
import SettingsPage from "../pages/settings";
import OrderPage from "../pages/job";
import PublicPage from "../pages/public";
import OrdersPage from "../pages/jobs";
import CustomersPage from "../pages/customers";
import CustomerPage from "../pages/customer";
import ServicesPage from '../pages/services'
import SupportsPage from '../pages/supports'
import SupportPage from '../pages/support'
import AdminPage from '../pages/admin'
import OnboardingPage from '../pages/onboarding'
import MobileAppPage from '../pages/mobile_app'
import KnowledgesPage from "../pages/knowledges_view";

export interface RouteProps {
  title: string;
  key: string;
  icon?: React.ReactNode;
  url: string;
  component: React.ReactNode;
  private?: boolean;
  menu?: boolean;
  condition?: (data: { user: any; role: any; }) => boolean;
}
const routes: RouteProps[] = [
  {
    title: "Login",
    key: "login",
    icon: <FaLock />,
    url: "/login",
    component: <LoginPage />,
  },
  {
    title: "Signup",
    key: "signup",
    icon: <FaSign />,
    url: "/signup",
    component: <SignupPage />,
  },
  {
    title: "Client Signup",
    key: "signup-customer",
    icon: <FaSign />,
    url: "/signup/customer",
    component: <SignupPage customer />,
  },
  {
    title: "Lifetime Deal Signup",
    key: "signup-appsumo",
    icon: <FaSign />,
    url: "/partner",
    component: <SignupPage partner />,
  },
  {
    title: "Invite",
    key: "invite",
    url: "/invite/:key",
    component: <InvitePage />,
  },
  {
    title: "Onboarding",
    key: "onboarding",
    url: "/welcome",
    component: <OnboardingPage />,
  },
  {
    title: "Validate",
    key: "validate",
    url: "/validate/:key",
    component: <ValidatePage />,
  },
  {
    title: "Recuperar senha",
    key: "recover",
    url: "/recover/:key",
    component: <RecoverPage />,
  },
  {
    title: "Dashboard",
    key: "home",
    icon: <FaChartLine />,
    url: "/",
    menu: true,
    private: true,
    component: <HomePage />,
  },
  {
    title: "Clients",
    key: "customers",
    icon: <FaPeopleArrows />,
    url: "/clients",
    condition: ({role}) => ["Admin", "Owner"].includes(role?.profile?.title),
    menu: true,
    private: true,
    component: <CustomersPage />,
  },
  {
    title: "Orders",
    key: "orders",
    icon: <FaThList />,
    url: "/orders",
    condition: ({role}) => ["Admin", "Owner", "Project Manager", "Client"].includes(role?.profile?.title),
    menu: true,
    private: true,
    component: <OrdersPage />,
  },
  {
    title: "Support",
    key: "support",
    icon: <FaPhone />,
    url: "/support",
    condition: ({role}) => ["Admin", "Owner", "Support", "Client"].includes(role?.profile?.title),
    menu: true,
    private: true,
    component: <SupportsPage />,
  },
  {
    title: "Support",
    key: "support",
    icon: <FaPhone />,
    url: "/support/:supportId",
    menu: false,
    private: true,
    component: <SupportPage />,
  },
  {
    title: "Clients",
    key: "clients-page",
    icon: <FaHome />,
    url: "/clients/:customerId",
    condition: ({role}) => ["Admin", "Owner"].includes(role?.profile?.title),
    menu: false,
    private: true,
    component: <CustomerPage />,
  },
  {
    title: "Services",
    key: "services",
    icon: <FaSlidersH />,
    url: "/services",
    condition: ({role}) => ["Admin", "Owner"].includes(role?.profile?.title),
    menu: true,
    private: true,
    component: <ServicesPage />,
  },
  {
    title: "Settings",
    key: "settings",
    icon: <FaWrench />,
    url: "/settings",
    menu: true,
    private: true,
    component: <SettingsPage />,
  },
  {
    title: "Mobile App",
    key: "mobile-app",
    icon: <FaWrench />,
    url: "/mobile-app",
    menu: false,
    private: true,
    component: <MobileAppPage />,
  },
  {
    title: "Admin",
    key: "admin",
    icon: <FaDashcube />,
    url: "/admin-awaz",
    condition: ({user}) => user?.isAdmin,
    menu: true,
    private: true,
    component: <AdminPage />,
  },
  {
    title: "Accelerator",
    key: "knowledge",
    icon: <FaBook />,
    url: "/knowledge",
    condition: ({role}) => ["Admin", "Owner", "Project Manager", "Support"].includes(role?.profile?.title),
    menu: true,
    private: true,
    component: <KnowledgesPage />,
  },
  {
    title: "Order",
    key: "order",
    url: "/orders/:orderId",
    condition: ({role}) => !!role,
    menu: false,
    private: true,
    component: <OrdersPage />,
  },
  {
    title: "Share",
    key: "public",
    url: "/:key",
    menu: false,
    private: false,
    component: <PublicPage />,
  },
  {
    title: "Política de Privacidade",
    key: "signup-company",
    icon: <FaRegFileWord />,
    url: "/politica-de-privacidade",
    component: <PolicyPage />,
  },
];

export default routes;
