import { Paper } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFThumbnail = (props) => {
    const { pdfBlobUrl = null, url = null, height = 300, width = 200 } = props;
    const [numPages, setNumPages] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);

    useEffect(() => {
        if(pdfBlobUrl){
            pdfjs.getDocument(pdfBlobUrl).promise.then((pdf) => {
                setNumPages(pdf.numPages);
                pdf.getPage(1).then((page) => {
                    const canvas = document.createElement("canvas");
                    const context = canvas.getContext("2d");
                    const viewport = page.getViewport({ scale: 1.5 });
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;
                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport,
                    };
                    page.render(renderContext).promise.then(() => {
                        const dataUrl = canvas.toDataURL("image/png");
                        setThumbnailUrl(dataUrl);
                    });
                });
            });
        }
    }, [pdfBlobUrl]);

    useEffect(() => {
        if(url){
            // const loadingTask = pdfjs.getDocument(url);
            // loadingTask.promise.then((pdf) => {
            //     pdf.getPage(1).then((page) => {
            //         const canvas = document.createElement("canvas");
            //         const context = canvas.getContext("2d");
            //         const viewport = page.getViewport({ scale: 1.5 });
            //         canvas.height = viewport.height;
            //         canvas.width = viewport.width;
            //         const renderContext = {
            //         canvasContext: context,
            //         viewport: viewport,
            //         };
            //         page.render(renderContext).promise.then(() => {
            //         const dataUrl = canvas.toDataURL("image/png");
            //         setThumbnailUrl(dataUrl);
            //         });
            //     });
            //     setNumPages(pdf.numPages);
            // });
        }
    }, [url]);

    return (
        <Paper shadow="lg" style={{
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: `url(${thumbnailUrl})`,
            height,
            width,
        }} />
    );
};

export default PDFThumbnail;
