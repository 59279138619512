import { Axios } from "axios"

const renderResponse = (method:string, url:string) => {
  return new Promise((resolve, reject) => {
    const mocked = require(`./${method.toLowerCase()}.json`);
    const urlSplited = url.split("/").slice(1);
    let matchItem;
    for(let key in mocked){
      if(!matchItem){
        const keySplit = key.split("/").slice(1);
        if(urlSplited.every((chunk, index) => keySplit[index] && 
          (keySplit[index].startsWith(":") || chunk === keySplit[index]))
        ){
          matchItem = key;
        }
      }
    }
    if(!matchItem) reject(new Error(`Nenhum mockup definido para ${method} -> ${url}`));
    else{
      setTimeout(() => resolve({data: mocked[matchItem]}), 1000)
    }
  });
}

class MockupAxios extends Axios{
  request({url, method}): Promise<any> { return renderResponse(method, url); }
  get(url: string): Promise<any> { return renderResponse("get", url); }
  post(url: string): Promise<any> { return renderResponse("post", url); }
  delete(url: string): Promise<any> { return renderResponse("delete", url); }
  patch(url: string): Promise<any> { return renderResponse("patch", url); }
  put(url: string): Promise<any> { return renderResponse("put", url); }
}

export default MockupAxios;