import { Grid, Paper } from "@mantine/core";
import React, { useState } from "react";
import GoogleAnalytics from "../components/integrations/google_analytics";
import Scripts from "../components/integrations/scripts";
import PaymentMethod from "../components/integrations/payment_method";
import Webhooks from "../components/integrations/webhooks";
import Zapier from "../components/integrations/zapier";
import Stripe from "../components/integrations/stripe";
import PayPay from "../components/integrations/paypal";
import Sendgrid from "../components/integrations/sendgrid";

export default function Integrations(){
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    
    return <div style={{position: 'relative'}}>
        <Grid>
            {/* <Grid.Col span={{ base: 12, md: 6 }}>
                <Paper p="md" shadow="md">
                    <PaymentMethod />
                </Paper>
            </Grid.Col> */}
            <Grid.Col span={{ base: 12, md: 6 }}>
                <Paper p="md" shadow="md">
                    <Zapier />
                </Paper>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
                <Paper p="md" shadow="md">
                    <Stripe />
                </Paper>
            </Grid.Col>
            {/* <Grid.Col span={{ base: 12, md: 6 }}>
                <Paper p="md" shadow="md">
                    <PayPay />
                </Paper>
            </Grid.Col> */}
            <Grid.Col span={{ base: 12, md: 6 }}>
                <Paper p="md" shadow="md">
                    <GoogleAnalytics />
                </Paper>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 12 }}>
                <Paper p="md" shadow="md">
                    <Sendgrid />
                </Paper>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 12 }}>
                <Paper p="md" shadow="md">
                    <Webhooks />
                </Paper>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 12 }}>
                <Paper p="md" shadow="md">
                    <Scripts />
                </Paper>
            </Grid.Col>
        </Grid>
    </div>
}
