import { Box, Paper, Text, Title } from "@mantine/core";
import Base256 from "base256-encoding";
import moment from "moment";
import quotedPrintable from "quoted-printable";
import React, { useEffect, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import { FaCheck, FaPaperclip } from "react-icons/fa";
import { useAuth } from "../../contexts/auth.context";
import { getExtenseDatetime, markdown } from "../../utility/util";
import PDFThumbnail from "../pdf_thumb";

export default function ConversationBox({
  messages = [],
  history = [],
  messagesStatus = {},
  ...others
}) {
  const scrollRef = useRef<any>();

  const { user: currentUser } = useAuth();

  useEffect(() => {
    setTimeout(() => {
      scrollRef.current?.scrollTo({
        top: scrollRef.current?.scrollHeight,
        behavior: "smooth",
      });
    }, 300);
  }, [scrollRef, messages.length]);

  const removeHash = /--0000000000.*$/;

  const renderMessage = (message) => {
    if(message.render) return message.render();
    const selfMessage = message.createdBy?._id === currentUser._id;
    const body = message?.props?.find(p => p.key === "body")?.value;
    let decodedBody : any = null;
    let isIso88591 = false;
    if(body) {
      decodedBody = Base256.decodeStr(quotedPrintable.decode(body));
      isIso88591 = Base256.is(decodedBody);
    }
    return (
      <div style={{ textAlign: selfMessage ? "right" : "left" }}>
        <Paper
          m="xs"
          p="xs"
          style={{
            border: 0,
            maxWidth: "80%",
            minWidth: 180,
            display: "inline-block",
            backgroundColor: selfMessage
                ? "#fab007"
                : "#fd7e13",
            borderRadius: selfMessage
                ? "10px 10px 0px 10px"
                : "10px 10px 10px 0px",
          }}
        >
          {!selfMessage && message.createdBy && (
            <Text c="white" mb="sm" size="xs" w="bold">{message.createdBy.name}</Text>
          )}
          {(message.files || []).map(file => <div
            onClick={() => window.open(file.url,"_blank") }
            style={{
              cursor: "pointer",
              padding: "10px 0",
            }}
          >
            {file.mimetype?.includes("image")
            ? <div
              style={{
                maxWidth: '100%',
                height: 300,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // background: "#F4F4F4",
                // border: "1px solid #EFEFEF",
                borderRadius: 10,
                backgroundPosition: "center center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${file.url})`,
              }}
            />
            // : file.mimetype?.includes("pdf")
            // ? <PDFThumbnail pdfBlobUrl={file.url} />
            : <div style={{padding: 20, borderRadius: 10, background: '#FAFAFA', textAlign: 'center'}}><FaPaperclip /> {file.original_name ?? "Attachment"}</div>}
            </div>
          )}
          
          <Text c="white" size="sm" className="conversation-box-text" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
              {markdown(message.text)}
          </Text>
          {["read", "delivered", "sent"].includes(
            messagesStatus[message._id]
          ) && (
              <FaCheck
                size={13}
                color={
                  messagesStatus[message._id] === "read"
                    ? "rgb(119, 181, 220)"
                    : "rgb(138, 149, 131)"
                }
              />
            )}
          {["read", "delivered"].includes(messagesStatus[message._id]) && (
            <FaCheck
              style={{ marginLeft: -7 }}
              size={13}
              color={
                messagesStatus[message._id] === "read"
                  ? "rgb(119, 181, 220)"
                  : "rgb(138, 149, 131)"
              }
            />
          )}
          {(message.error || message.status === "failed") && (
            <Text size="xs" c="red">
              {message.error || "Falha ao enviar mensagem"}
            </Text>
          )}
          <Text c="#FFFFFF" size="xs" ta="right">
            {getExtenseDatetime(message.createdAt)}
          </Text>
        </Paper>
      </div>
    );
  };

  return (
    <Box p="md" style={{ overflowY: "auto", flex: 1 }} ref={scrollRef} {...others}>
      {[
        ...(messages || []).map((item: any) => ({
          type: "message",
          action: "",
          datetime: item.createdAt,
          item,
        })),
        ...(history || []).map((item: any) => ({
          type: "history",
          action: "entrou na",
          datetime: item.join_at,
          item,
        })),
        ...(history || [])
          .filter((item: any) => item.leave_at)
          .map((item: any) => ({
            type: "history",
            action: "saiu da",
            datetime: item.leave_at,
            item,
          })),
      ]
        .sort((a: any, b: any) => (a.datetime > b.datetime ? 1 : -1))
        .map(({ item, type, datetime, action }) =>
          type === "history" ? (
            <Text
              size="xs"
              c="orange"
              m="md"
              style={{
                textAlign: "center",
                background: "#F9F9F9",
                border: "1px solid #EFEFEF",
                padding: "2px 20px",
              }}
            >
              {moment(datetime).format("DD/MM/YYYY HH:mm")} -{" "}
              <b>{item?.createdBy?.name?.split(" ").slice(0, 2).join(" ")}</b>{" "}
              {action} conversa
            </Text>
          ) : (
            renderMessage(item)
          )
        )}
    </Box>
  );
}
