import { api } from "./api"

export const GET_ALL_SERVICES = async () => {
    const {data} = await api.get("/services")
    return data.services || [];
}

export const SAVE_SERVICES_ORDER = async (ids = []) => {
    const {data} = await api.patch("/services/order", ids)
    return data;
}

export const DELETE_SERVICE = async (id) => {
    const {data} = await api.delete(`/services/${id}`)
    return data;
}

export const CREATE_SERVICE = async (params : any) => {
    const {data} = await api.post(`/services`, params)
    return data;
}

export const UPDATE_SERVICE = async (id: string, params : any) => {
    const {data} = await api.patch(`/services/${id}`, params)
    return data;
}

