import { ActionIcon, Box, Button, Grid, Group, Image, Paper, Text, Title, UnstyledButton } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import InputField, { InputFieldMasks, InputFieldTypes } from "../components/input_field";
import { HtmlEditor } from "../components/html_editor";
import { useApp } from "../contexts/app.context";
import { DELETE_KNOWLEDGE, DELETE_KNOWLEDGE_CATEGORY, GET_ALL_KNOWLEDGES, SAVE_KNOWLEDGE, SAVE_KNOWLEDGE_CATEGORY } from "../services/knowledges";
import { FaArrowLeft } from "react-icons/fa";
import SunEditor from 'suneditor-react';

export default function KnowledgesPage(){
    const [knowledges, setKnowledges] = useState<any[]>([]);
    const [selectedKnowledgeCategory, setSelectedKnowledgeCategory] = useState<any>(null);
    const [selectedKnowledge, setSelectedKnowledge] = useState<any>(null);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const { confirmDialog } = useApp();

    const loadKnowledges = () => {
        GET_ALL_KNOWLEDGES()
        .then(res => {
            setKnowledges(res);
            if(selectedKnowledgeCategory){
                setSelectedKnowledgeCategory(res.find(r => r._id === selectedKnowledgeCategory?._id) ?? selectedKnowledge)
            }
        })
        .catch(err => {
            notifications.show({ message: err.message, color: "red" })
        })
    }
    
    const handleSaveCategory = () => {
        let params = { ...selectedKnowledgeCategory };

        setLoadingSave(true)
        SAVE_KNOWLEDGE_CATEGORY(params)
        .then((res) => {
            notifications.show({ message: "Category saved!", color: "green" })
            loadKnowledges();
            setSelectedKnowledgeCategory(res)
            setLoadingSave(false);
        })
        .catch(err => {
            notifications.show({ message: err.message, color: "red" })
            setLoadingSave(false);
        })
    }
    
    const handleSave = () => {
        let params = { ...selectedKnowledge };

        setLoadingSave(true)
        SAVE_KNOWLEDGE({...params, category: selectedKnowledgeCategory._id})
        .then((res) => {
            notifications.show({ message: "Knowledge saved!", color: "green" })
            loadKnowledges();
            setSelectedKnowledge(res)
            setLoadingSave(false);
        })
        .catch(err => {
            notifications.show({ message: err.message, color: "red" })
            setLoadingSave(false);
        })
    }

    useEffect(loadKnowledges, []);
    
    return <div style={{position: 'relative'}}>
        <Grid>
            {
                selectedKnowledge
                ? <Grid.Col span={{ base: 12, md: 12 }}>
                    <Group mb="md">
                        <ActionIcon onClick={() => setSelectedKnowledge(null)}>
                            <FaArrowLeft />
                        </ActionIcon>
                        <div style={{flex: 1}}></div>
                        <Button disabled={!selectedKnowledge._id} color="gray" variant="outline"
                            onClick={() => {
                                confirmDialog(
                                    { text: "Do you realy wants to delete this knowledge?" },
                                    ({ confirmed }) => {
                                        if(confirmed) DELETE_KNOWLEDGE(selectedKnowledge?._id)
                                        .then(() => {
                                            loadKnowledges();
                                            setSelectedKnowledge(null);
                                        })
                                        .catch(err => {
                                            notifications.show({ message: err.message })
                                        })
                                    }
                                )
                            }}
                        >Delete Item</Button>
                    </Group>

                    <Paper p="md" mb="md">
                        <InputField
                            name="image"
                            onChange={({image}) => setSelectedKnowledge(sp => ({...sp, image}))}
                            value={selectedKnowledge?.image}
                            title="Image"
                            fieldType={InputFieldTypes.IMAGE}
                        />
                        <InputField
                            name="title"
                            mt="md"
                            onChange={({title}) => setSelectedKnowledge(sp => ({...sp, title}))}
                            value={selectedKnowledge?.title}
                            title="Title"
                        />

                        <Box mt="md"></Box>
                        <SunEditor
                            // value={selectedKnowledge?.body}
                            name="body"
                            setContents={selectedKnowledge?.body}
                            height="200px"
                            onChange={(body) => setSelectedKnowledge(sp => ({...sp, body}))}
                        />
                    </Paper>

                    <Group justify="flex-end" mt="md">
                        <Button size="md" onClick={handleSave} loading={loadingSave} color="green" className="draw-button">Save Item</Button>
                    </Group>
                </Grid.Col>
                : <Grid.Col span={{ base: 12, md: 12 }}>
                    <Group mb="md">
                        <InputField
                            name="pl"
                            style={{flex: 1}}
                            onChange={({pl}) => {
                                pl === "new"
                                ? setSelectedKnowledgeCategory({  })
                                : setSelectedKnowledgeCategory(knowledges.find(p => p._id === pl))
                            }}
                            value={selectedKnowledgeCategory ? selectedKnowledgeCategory?._id || "new" : null}
                            fieldType={InputFieldTypes.SELECT}
                            clearable={false}
                            options={[
                                ...knowledges.map(p => ({label: p.title, value: p._id}))
                                .sort((a,b) => a.label > b.label ? 1 : -1),
                                { value: "new", label: "Add new knowledge" }
                            ]}
                        />
                        {selectedKnowledgeCategory?._id && <Button color="gray" variant="outline"
                            onClick={() => {
                                confirmDialog(
                                    { text: "Do you realy wants to delete this category?" },
                                    ({ confirmed }) => {
                                        if(confirmed) DELETE_KNOWLEDGE_CATEGORY(selectedKnowledgeCategory?._id)
                                        .then(() => {
                                            loadKnowledges();
                                            setSelectedKnowledgeCategory(null);
                                        })
                                        .catch(err => {
                                            notifications.show({ message: err.message })
                                        })
                                    }
                                )
                            }}
                        >Delete Category</Button>}
                    </Group>

                    {selectedKnowledgeCategory && <>
                        <Paper p="md" mb="md">
                            <InputField
                                name="image"
                                onChange={({image}) => setSelectedKnowledgeCategory(sp => ({...sp, image}))}
                                value={selectedKnowledgeCategory?.image}
                                title="Image"
                                fieldType={InputFieldTypes.IMAGE}
                            />
                            <InputField
                                name="title"
                                mt="md"
                                onChange={({title}) => setSelectedKnowledgeCategory(sp => ({...sp, title}))}
                                value={selectedKnowledgeCategory?.title}
                                title="Title"
                            />
                            <InputField
                                name="description"
                                mt="md"
                                fieldType={InputFieldTypes.TEXTAREA}
                                onChange={({description}) => setSelectedKnowledgeCategory(sp => ({...sp, description}))}
                                value={selectedKnowledgeCategory?.description}
                                title="Description"
                            />
                        </Paper>
                        <Paper p="md">
                            <Title order={5}>Items</Title>
                            <Grid>
                                {
                                    (selectedKnowledgeCategory.items ?? [])
                                    .sort((a,b) => a.title > b.title ? 1 : -1)
                                    .map(item => (
                                        <Grid.Col span={{base: 12, md: 4}}>
                                        <UnstyledButton style={{width: '100%'}} onClick={() => setSelectedKnowledge(item)}>
                                            <Paper p="md" mb="md">
                                                <Title mb="md" order={4}>{item.title}</Title>
                                                <Image src={item.image} style={{width: '100%'}} />
                                            </Paper>
                                        </UnstyledButton>
                                        </Grid.Col>
                                    ))
                                }
                            </Grid>
                            <Group justify="right">
                                <Button disabled={!selectedKnowledgeCategory?._id} onClick={() => setSelectedKnowledge({})}>Add New</Button>
                            </Group>
                        </Paper>
                        <Group justify="flex-end" mt="md">
                            <Button size="md" onClick={handleSaveCategory} loading={loadingSave} color="green" className="draw-button">Save Category</Button>
                        </Group>
                    </>}

                </Grid.Col>
            }
        </Grid>
    </div>
}
