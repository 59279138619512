import {
  ActionIcon,
  AppShell,
  Avatar,
  Badge,
  Box,
  Burger,
  Button,
  Divider,
  Group,
  Indicator,
  Menu,
  Modal,
  Paper,
  Switch,
  Text,
  Title,
  Tooltip,
  UnstyledButton,
  useMantineTheme
} from "@mantine/core";
import CookieConsent from "react-cookie-consent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMediaQuery } from "@mantine/hooks";
import { notifications, showNotification } from "@mantine/notifications";
import React, { useEffect, useRef, useState } from "react";
import { FaAppStore, FaBell, FaChevronDown, FaCompressArrowsAlt, FaExpand, FaFacebook, FaGooglePlay, FaInstagram, FaLinkedin, FaPinterest, FaPlus, FaReddit, FaSignOutAlt, FaSnapchat, FaTiktok, FaTimes, FaTwitch, FaTwitter, FaUser, FaWhatsapp } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RolesSelect from "../components/roles_select";
import { useApp } from "../contexts/app.context";
import { useAuth } from "../contexts/auth.context";
import { useLanguage } from "../contexts/language.context";
import NewJobPage from "../pages/new_job";
import { GET_ALL_INVITES, SEND_INVITE } from "../services/companies";
import { GET_ALL_PROFILES } from "../services/profiles";
import { DELETE_USER, GET_ALL_NOTIFICATIONS, READ_NOTIFICATION, UPDATE_USER } from "../services/users";
import routes from "../utility/routes";
import { getExtenseDatetime, useQuery } from "../utility/util";
import InputField, { InputFieldTypes } from "./input_field";
import { RESEND_VALIDATION } from "../services/auth";
import JobPage from "../pages/job";
import moment from "moment";

export type LayoutProps = {
  children?: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const theme = useMantineTheme();

  const {
    user: currentUser,
    hasPermission,
    logout,
    planData,
    role,
    roles,
    userData,
    onChangeRole,
    loadUserData,
    user,
  } = useAuth();
  const { socket, loadAgents, agents, startPlan, configs, mobileApp } = useApp();
  const { confirmDialog, newOrder, setNewOrder } = useApp();
  const { lang, setLang, str } = useLanguage();
  const isMobile = useMediaQuery('(max-width: 991px)');

  const lsOpened = localStorage.getItem("@retainr/opened-sidebar");

  const navigate = useNavigate();
  const query = useQuery();

  const location = useLocation();
  const { orderId: paramsOrderId } = useParams();
  const queryOrderId = query.get("order");
  const orderId = queryOrderId ?? paramsOrderId;

  const jobRef = useRef<any>();

  const [opened, setOpened] = useState(false);
  const [openedSidebar, setOpenedSidebar] = useState<boolean>(isMobile ? true : [null, undefined].includes(lsOpened) ? lsOpened === "true" : true);
  const [activeMenu, setActiveMenu] = useState(null);
  const [openTeam, setOpenTeam] = useState(null);
  const [inviteMails, setInviteMails] = useState({ email: "", profile: null });
  const [profiles, setProfiles] = React.useState<any[]>([]);
  const [invites, setInvites] = React.useState<any[]>([]);
  const [users, setUsers] = React.useState<any[]>([]);
  const [notificationsList, setNotificationsList] = React.useState<any[]>([]);
  const [openNotificationsMenu, setOpenNotificationsMenu] = React.useState<boolean>(false);

  const primaryColor = {
    "black": "#2C2C2C",
    "green": "#B7CD4B",
    "yellow": "#FFD540",
    "orange": "#FF4D18",
    "white": "#FAFAFA",
    "blue": "#EDF8FD",
    "pink": "#FFF0F6",
  }[configs.navbar ?? "white"];

  // const handleCloseJob = () => {
  //   if(paramsOrderId) navigate("/orders");
  //   if(queryOrderId){
  //       query.delete("order");
  //       navigate(`${location.pathname}${query.toString() ? `?${query.toString()}` : ''}`);
  //   }
  // }

  const loadProfiles = () => {
    GET_ALL_PROFILES()
      .then(res => {
        setProfiles(res);
        setInviteMails(im => ({ ...im, profile: res.find(r => r.title === "Project Manager")?._id }))
      })
      .catch(err => { })
  }

  const loadNotifications = () => {
    GET_ALL_NOTIFICATIONS()
      .then((res) => {
        setNotificationsList(res);
      })
      .catch(err => { })
  }

  const readNotification = (id) => {
    READ_NOTIFICATION(id)
      .then(() => {
        setNotificationsList(nl => nl.map(n => n._id === id ? { ...n, read: moment().format() } : n));
      })
      .catch(err => {
        // showNotification({message: err.message})
      })
  }

  const loadInvites = () => {
    GET_ALL_INVITES()
      .then(res => {
        setInvites(res.filter(i => i?.profile?.title !== "Client" && i.status === 0));
      })
      .catch(err => { })
  }

  const handleInvite = () => {
    // const canInvite = (planData?.seats || []).length > agents.length + invites.length;
    // if(!canInvite){
    //   notifications.show({ message: "To invite someone you need to upgrade your plan seats quantity." , color: 'yellow' })
    //   navigate("/settings#users")
    // }else{
    let previous = inviteMails;
    setInviteMails(im => ({ ...im, email: "" }))
    SEND_INVITE({ email: inviteMails.email, profile: inviteMails.profile })
      .then(res => {
        notifications.show({ message: str(`INVITE_SENT`), color: "green" })
        loadInvites();
      })
      .catch(err => {
        setInviteMails(previous);
        notifications.show({ message: err.message, color: "red" })
      })
    // }
  }

  const resendValidationMail = () => {
    RESEND_VALIDATION()
      .then(() => {
        notifications.show({ message: "Validation e-mail send.", color: "green" })
      })
      .catch((err) => {
        notifications.show({ message: err.message, color: "red" })
      })
  }

  const handleUpdateUserProfile = (userId, profileId) => {
    let profile = profiles.find(p => p._id === profileId);
    let previous = users;
    UPDATE_USER(userId, { profile: profile._id })
      .then(() => {
        loadAgents()
      })
      .catch(err => {
        notifications.show({ message: err.message, color: 'red' })
      })
  }

  const navbarContent: {
    title: string;
    key: string;
    icon: React.ReactNode;
    url: string;
  }[] = routes
    .filter(
      (route) =>
        route.menu &&
        hasPermission(route) &&
        (!route.condition || route.condition({ user: userData?.user, role }))
    )
    .map(({ title, key, icon, url }) => ({ title, key, icon, url }));

  useEffect(() => {
    localStorage.setItem("@retainr/opened-sidebar", openedSidebar.toString())
  }, [openedSidebar]);

  useEffect(() => {
    if (userData && role && userData?.plan?.trial && role?.profile?.title !== "Client") { startPlan(); }
  }, [userData, role]);

  useEffect(() => {
    if (isMobile) setOpenedSidebar(true)
  }, [isMobile]);

  useEffect(() => {
    if (socket) {
      const func = (notification) => {
        notifications.show({ message: notification.body, title: notification.title, color: "yellow" });
        setNotificationsList(n => [notification, ...n])
      };
      socket.on(`user-notifications-${currentUser?._id}`, func);
      return () => {
        socket.removeAllListeners(`user-notifications-${currentUser?._id}`);
      };
    }
  }, [socket, currentUser]);

  useEffect(() => {
    loadProfiles()
    loadInvites()
    loadNotifications()
  }, [role]);

  // React.useEffect(() => {
  //   if(orderId){
  //     const handleClickOutside = (event) => {
  //       if (jobRef.current && !jobRef.current.contains(event.target)) {
  //         handleCloseJob();
  //       }
  //     }
  //     document.addEventListener("mousedown", handleClickOutside);
  //     return () => {
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   }
  // }, [jobRef, orderId]);

  React.useEffect(() => {
    let url = window.location.pathname;
    let findIndex = navbarContent.findIndex((r) => r.url === url);

    if (findIndex !== -1) {
      setActiveMenu(findIndex);
    } else {
      findIndex = navbarContent.findIndex((r) => r.url.split("/").some(s => s && url.split("/").includes(s)));
      if (findIndex !== -1) setActiveMenu(findIndex);
    }
  }, [window.location.pathname]);

  return <AppShell
    header={{ height: 80 }}
    navbar={{ width: openedSidebar ? 240 : 60, breakpoint: 'md', collapsed: { mobile: !opened } }}
    styles={{
      main: {
        // marginRight: -15,
        // marginTop: -20,
        // marginLeft: -15,
      },
      root: { padding: 0 },
      header: {
        left: isMobile ? 0 : openedSidebar ? 240 : 60,
      },
      navbar: {
        top: 0,
        height: '100vh',
        zIndex: 2,
        padding: 0,
        paddingTop: isMobile ? 70 : 0,
        background: primaryColor,
      }
    }}
    padding="md"
  >
    <AppShell.Header>
      <div style={{
        backgroundImage: "linear-gradient(270deg, #FAFAFA 0%, #FAFAFA 100%)",
        // backgroundColor: primaryColor,
        zIndex: 2,
        height: 80,
        padding: isMobile ? '0 8px' : '0 20px',
        WebkitBoxShadow: `2px 0px 5px 0px #E0E0E0`,
        MozBoxShadow: `2px 0px 5px 0px #E0E0E0`,
        boxShadow: `2px 0px 5px 0px #E0E0E0`,
        borderBottom: `1px solid #E0E0E0`,
      }}>
        <div
          style={{ display: "flex", alignItems: "center", height: "100%", textAlign: 'center' }}
        >
          {isMobile && <Burger
            opened={opened}
            onClick={() => setOpened((o) => !o)}
            size="sm"
            color={{
              "black": "#FBFBFB",
              "green": "#000000",
              "yellow": "#000000",
              "orange": "#FBFBFB",
              "blue": "#FBFBFB",
              "pink": "#FBFBFB",
              "white": "#000000",
            }[configs.navbar ?? "white"]}
            mr="xl"
          />}

          {isMobile && <div style={{ flex: 1 }}></div>}

          {!isMobile &&
            <Group style={{ flex: 1 }}>
              {role?.profile?.title !== "Client" && <Menu
                position="bottom-start"
                opened={openTeam}
                onOpen={() => setOpenTeam(true)}>
                <Menu.Target>
                  <UnstyledButton>
                    <Group>
                      <Button size="sm" variant="outline" color="black" style={{ backgroundColor: 'white' }} leftSection={<FaPlus />}>{str("HEADER.ADD_TEAM_MEMBERS")}</Button>

                      {agents.map((us, i) => (
                        <Tooltip label={us.name}>
                          <Avatar style={{ borderRadius: 40, border: '1px solid black', marginLeft: i === 0 ? 0 : -32, zIndex: i + 1 }} color="orange" variant="filled" src={us.image} />
                        </Tooltip>
                      ))}
                    </Group>
                  </UnstyledButton>
                </Menu.Target>
                <Menu.Dropdown
                  style={{
                    minWidth: 500,
                  }}
                  className="dropdown-arrow"
                  m="md"
                >
                  <Box p="md">
                    <Group>
                      <Text style={{ flex: 1, textAlign: 'left' }} size="md" fw="bold">{str("HEADER.INVITE_YOUR_TEAM")}</Text>
                      <ActionIcon color="gray" variant="subtle" onClick={() => setOpenTeam(false)}><FaTimes /></ActionIcon>
                    </Group>
                    <Group mt="md">
                      <InputField
                        style={{ flex: 1 }}
                        name="email"
                        value={inviteMails.email}
                        onChange={({ email }) => setInviteMails(im => ({ ...im, email }))}
                        variant="filled"
                        placeholder={str("HEADER.TYPE_EMAIL_TO_INVITE")}
                      />
                      <InputField
                        name="profile"
                        onChange={({ profile }) => setInviteMails(im => ({ ...im, profile }))}
                        value={inviteMails.profile}
                        searchable={false}
                        clearable={false}
                        fieldType={InputFieldTypes.SELECT}
                        options={profiles
                          .filter(p =>
                            (p.title !== "Owner" || ["Owner"].includes(role?.profile?.title))
                            && p.title !== "Client")
                          .map(p => ({ label: p.title, value: p._id }))}
                      />
                      <Button
                        disabled={["Support", "Project Manager"].includes(role?.profile?.title)}
                        variant="filled" color="orange" onClick={handleInvite}>{str("INVITE")}</Button>
                    </Group>
                    <Text mt="xs" c="gray" size="xs">{{
                      "Support": "ⓘ Access to support portal for handling client requests.",
                      "Project Manager": "ⓘ Navigates service orders and delegates tasks to team members.",
                      "Admin": "ⓘ Total access to all roles and portals.",
                      "Owner": "ⓘ Total access to all roles and portals.",
                    }[profiles.find(p => p._id === inviteMails.profile)?.title]}</Text>
                    <Divider mt="xl" mb="xl" />
                    <div style={{ height: 220, overflowY: 'auto' }}>
                      {
                        agents.map(u => (<Group mb="xl">
                          <Avatar src={u.image} />
                          <Box style={{ flex: 1 }}>
                            <Title ta="left" order={5}>{u.name}</Title>
                          </Box>
                          <InputField
                            name="profile"
                            onChange={({ profile }) => {
                              if (profile === "delete") {
                                confirmDialog({ text: str("CONFIRMATION.DELETE_USER") }, ({ confirmed }) => {
                                  if (confirmed) DELETE_USER(u._id)
                                    .then(res => {
                                      loadAgents();
                                    })
                                    .catch(err => {
                                      notifications.show({ message: err.message, color: "red" })
                                    })
                                })
                              } else {
                                handleUpdateUserProfile(u._id, profile)
                              }
                            }}
                            value={u.profile._id}
                            searchable={false}
                            clearable={false}
                            disabled={
                              userData?.user?._id === u._id ||
                              (u.profile.title === "Owner" && (role?.profile?.title !== "Owner")) ||
                              ["Support", "Project Manager"].includes(role?.profile?.title)
                            }
                            fieldType={InputFieldTypes.SELECT}
                            options={
                              [
                                ...profiles
                                  .filter(p => p.title !== "Client")
                                  .map(p => ({ label: p.title, value: p._id })),
                                { label: str("HEADER.REMOVE_MEMBER"), value: "delete" }
                              ]
                            }
                          />
                        </Group>))
                      }
                      {invites.map(i => (<Group mb="xl">
                        <Box style={{ flex: 1 }}>
                          <Title ta="left" order={5}>{i.email}</Title>
                        </Box>
                        <Badge color="gray">{str("HEADER.INVITE_SENT")}</Badge>
                      </Group>))}
                    </div>
                    {/* <Button variant="outline" c="gray" fullWidth>Copy Link</Button> */}
                  </Box>
                </Menu.Dropdown>
              </Menu>}
            </Group>
          }

          <Group style={{ gap: isMobile ? 4 : 20 }}>
            {userData?.plan?.trial && role?.profile?.title !== "Client" &&
              <Button
                onClick={() => startPlan()}
                variant="filled"
                style={{ borderColor: 'black', background: 'white' }}
                c="black"
                size={isMobile ? "xs" : "sm"}
              >{str("UPGRADE")}</Button>}
            {(planData?.active || role?.profile?.title === "Client") &&
              <Button
                onClick={() => setNewOrder({})}
                leftSection={<FaPlus />}
                variant="filled"
                style={{ borderColor: 'black', background: 'white' }}
                c="black"
                size={isMobile ? "xs" : "sm"}
              >{str("CREATE_NEW_ORDER")}</Button>}
            <Menu
              position="bottom-end"
              opened={openNotificationsMenu}
              onClose={() => setOpenNotificationsMenu(false)}
              onOpen={() => setOpenNotificationsMenu(true)}
            >
              <Menu.Target>
                <ActionIcon size={36} variant="outline" color="black" style={{ backgroundColor: 'white' }}>
                  <Badge style={{ position: 'absolute', top: -1 }} size="sm" color="yellow" variant="filled">{notificationsList.filter(n => !n.read).length}</Badge>
                  <FaBell />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown
                style={{
                  width: 250,
                }}
                m="md"
              >
                <Text p="sm" ta="center">{str("NOTIFICATIONS")}</Text>
                <Menu.Divider />
                <Box style={{ maxHeight: 300, overflowY: 'auto' }}>
                  {notificationsList.length === 0 && <Text size="xs" p="md" c="gray">{str("NO_NOTIFICATIONS")}</Text>}
                  {notificationsList.map(not => <>
                    <UnstyledButton p="xs" style={{ width: '100%' }} key={not._id} onClick={() => {
                      setOpenNotificationsMenu(false);
                      readNotification(not._id);
                      if (not.job) navigate(`/orders/${not.job._id}`);
                      else if (not.support) navigate(`/support/${not.support._id}`);
                    }}>
                      <Group>
                        <Text style={{ flex: 1 }} fw={not.read ? "normal" : "bold"} size="sm">{not.title}</Text>
                        {!not.read && <Indicator color="yellow" position="middle-end"></Indicator>}
                      </Group>
                      <Text fw={not.read ? "normal" : "bold"} size="xs" c="gray">{not.body}</Text>
                      <Text size="xs" c="gray" ta="right">{getExtenseDatetime(not.createdAt)}</Text>
                    </UnstyledButton>
                    <Divider mt="xs" mb="xs" />
                  </>)}
                </Box>
              </Menu.Dropdown>
            </Menu>

            <Menu position="bottom-end">
              <Menu.Target>
                <UnstyledButton>
                  <div style={{ display: 'flex', background: 'white', flexDirection: 'row', alignItems: 'center', border: '1px solid black', borderRadius: 8 }}>
                    {
                      <Avatar size={38} src={userData?.user?.image} color="black" style={{ borderRadius: '8px 0 0 8px' }} />
                    }
                    <FaChevronDown style={{ margin: '0 2px' }} size={12} />
                  </div>
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown
                style={{
                  minWidth: 300,
                }}
                className="dropdown-arrow"
                m="md"
              >
                <Text p="sm" ta="center">{currentUser?.name}</Text>
                <Menu.Divider />
                <Box mt="md" mb="md">
                  {role && roles.some(r => r.company._id === role.company._id && r.profile.title !== "Client") &&
                    <Switch mb="md" label="View as Client" checked={role?.profile?.title === "Client"} onChange={() => {
                      onChangeRole(role._id, role?.profile?.title !== "Client")
                    }} />}
                  <RolesSelect />
                  {/* <Text mt="xs" align="left" size="md" c="orange" fw="bold">Language</Text> */}
                  {/* <InputField
                    name="lang"
                    mt="xs"
                    fieldType={InputFieldTypes.SELECT}
                    value={lang}
                    clearable={false}
                    searchable={false}
                    onChange={({lang}) => setLang(lang)}
                    options={[
                      { label: "English", value: "en-US" },
                      { label: "Portuguese", value: "pt-BR" },
                    ]}
                  /> */}
                </Box>
                <Menu.Divider />
                <Menu.Item
                  onClick={() => {
                    confirmDialog(
                      { text: str("CONFIRMATION.LOGOUT") },
                      ({ confirmed }) => {
                        if (confirmed) logout();
                      }
                    );
                  }}
                  leftSection={<FaSignOutAlt />}
                  c="gray"
                >{str("LOGOUT")}</Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </div>
      </div>
    </AppShell.Header>

    <AppShell.Navbar>
      <AppShell.Section grow style={{ overflowY: "auto" }}>
        <div style={{ textAlign: 'center', padding: '40px 0 20px 0' }}><img
          src={openedSidebar ? configs.image ?? "/logo.png" : configs.icon ?? "/icon.png"}
          alt="Retainr"
          style={{ maxHeight: isMobile ? 60 : 80, maxWidth: isMobile ? '40%' : '60%', objectFit: 'contain' }}
        /></div>
        <div style={{ position: 'relative' }}>
          <div style={{
            background: '#FFFFFF',
            height: openedSidebar ? 65 : 50,
            position: 'absolute',
            top: navbarContent.findIndex((n, i) => i === activeMenu) > 0 ? navbarContent.findIndex((n, i) => i === activeMenu) * 65 : 0,
            left: 0,
            right: 0,
            zIndex: -1,
            transition: `0.3s all ease`,
            borderBottom: '4px solid #000000',
            borderRight: '1px solid #000000',
            borderLeft: '1px solid #000000',
            borderTop: '1px solid #000000',
          }}></div>
          {navbarContent.map((item, i) => (
            // <Tooltip key={`${i}`} position="bottom" label={item.title}>
            <UnstyledButton
              style={{
                width: '100%',
                height: 65,
                padding: !openedSidebar ? 0 : '20px 40px',
                color: "black",
                ...((i === activeMenu && openedSidebar) ? {
                  // backgroundColor: "white",
                } : {})
              }}
              onClick={() => {
                setOpened(false);
                navigate(item.url);
                setActiveMenu(i)
              }}
            >
              {!openedSidebar && <ActionIcon
                size="lg"
                ml="xs"
                mb="lg"
                variant="transparent"
                style={{
                  color: (i === activeMenu) ? "black" : {
                    "black": "#FBFBFB",
                    "green": "#000000",
                    "yellow": "#000000",
                    "orange": "#FBFBFB",
                    "blue": "#FBFBFB",
                    "pink": "#FBFBFB",
                    "white": "#000000",
                  }[configs.navbar ?? "white"]
                }}
                key={item.key}
              >{item.icon}</ActionIcon>}
              {openedSidebar && <Text
                size="sm"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: (i === activeMenu) ? "black" : {
                    "black": "#FBFBFB",
                    "green": "#000000",
                    "yellow": "#000000",
                    "orange": "#FBFBFB",
                    "blue": "#FBFBFB",
                    "pink": "#FBFBFB",
                    "white": "#000000",
                  }[configs.navbar ?? "white"],
                }}
              >
                {str(`NAVBAR.${item.key}`)}
              </Text>}
            </UnstyledButton>
            // </Tooltip>
          ))}
        </div>
      </AppShell.Section>
      {openedSidebar && userData?.company?.defaultDomain && role?.profile?.title !== "Client" && <AppShell.Section mb="xs">
        <Group align="center" justify="center" mt="xs">
          <Tooltip label="Share on Facebook"><ActionIcon variant="light" onClick={() => {
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(userData?.company?.defaultDomain)}`, '_blank');
          }}><FaFacebook /></ActionIcon></Tooltip>
          <Tooltip label="Share on Whatsapp"><ActionIcon variant="light" onClick={() => {
            window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(userData?.company?.name)}%20-%20${encodeURIComponent(userData?.company?.defaultDomain)}`, '_blank');
          }}><FaWhatsapp /></ActionIcon></Tooltip>
          <Tooltip label="Share on Reddit"><ActionIcon variant="light" onClick={() => {
            window.open(`https://www.reddit.com/submit?url=${encodeURIComponent(userData?.company?.defaultDomain)}&title=${encodeURIComponent(userData?.company?.name)}`, '_blank');
          }}><FaReddit /></ActionIcon></Tooltip>
          <Tooltip label="Share on X (Twitter)"><ActionIcon variant="light" onClick={() => {
            window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(userData?.company?.defaultDomain)}&text=${encodeURIComponent(userData?.company?.name)}`, '_blank');
          }}><FontAwesomeIcon icon={["fab", "x-twitter"]} /></ActionIcon></Tooltip>
          <Tooltip label="Share on Linkedin"><ActionIcon variant="light" onClick={() => {
            window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(userData?.company?.defaultDomain)}`, '_blank');
          }}><FaLinkedin /></ActionIcon></Tooltip>
        </Group>
      </AppShell.Section>}
      {mobileApp?.developer?.deployed && role?.profile?.title === "Client" && <Paper style={{ borderColor: '#FFFFFF' }} p="xl">
        <Text size="xs" mb="md" ta="center">Download The Mobile App</Text>
        <Group>
          {mobileApp?.developer?.play_store_url && <Box style={{ flex: 1 }}>
            <Paper onClick={() => window.open(mobileApp?.developer?.play_store_url, "_blank")} p="xs" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', borderRadius: 10, flexDirection: 'row', alignItems: 'center', background: '#FFFFFF' }}>
              <FaGooglePlay size={18} color="#000000" />
            </Paper>
          </Box>}
          {mobileApp?.developer?.apple_url && <Box style={{ flex: 1 }}>
            <Paper onClick={() => window.open(mobileApp?.developer?.apple_url, "_blank")} p="xs" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', borderRadius: 10, flexDirection: 'row', alignItems: 'center', background: '#FFFFFF' }}>
              <FaAppStore size={18} color="#000000" />
            </Paper>
          </Box>}
        </Group>
      </Paper>}
      {!isMobile && <AppShell.Section>
        <Tooltip
          position="right"
          label={openedSidebar ? str("COLLAPSE") : str("EXPAND")}
        >
          <Box>
            {openedSidebar
              ? <Button
                onClick={() => setOpenedSidebar(s => !s)}
                fullWidth
                variant="transparent"
                style={{
                  color: {
                    "black": "#FBFBFB",
                    "green": "#000000",
                    "yellow": "#000000",
                    "orange": "#FBFBFB",
                    "blue": "#FBFBFB",
                    "pink": "#FBFBFB",
                    "white": "#000000",
                  }[configs.navbar ?? "white"]
                }}
                size="xs"
                leftSection={<FaCompressArrowsAlt />}
              >{str("COLLAPSE")}</Button>
              : <ActionIcon
                style={{
                  color: {
                    "black": "#FBFBFB",
                    "green": "#000000",
                    "yellow": "#000000",
                    "orange": "#FBFBFB",
                    "blue": "#FBFBFB",
                    "pink": "#FBFBFB",
                    "white": "#000000",
                  }[configs.navbar ?? "white"]
                }}
                ml="xs" mb="xs" variant="transparent" onClick={() => setOpenedSidebar(s => !s)} size="lg"><FaExpand /></ActionIcon>}
            <Text></Text>
          </Box>
        </Tooltip>
      </AppShell.Section>}
    </AppShell.Navbar>

    <AppShell.Main styles={{ main: { background: '#FAFAFA' } }}>
      <div style={{ position: 'relative' }}>
        <div id="principal-content" style={{ padding: '0 0 200px 0' }}>
          <div>
            {children}
          </div>
        </div>

        {user && !userData?.user?.verified && <UnstyledButton style={{ width: '100%' }} onClick={resendValidationMail}>
          <Box p="xl" style={{ position: 'fixed', zIndex: 10, left: 0, bottom: 0, right: 0, textAlign: 'center', background: '#ff4d18' }}>
            <Text c="white">Your e-mail wasn't verified yet.</Text>
            <Text c="white" fw="bold">Click here to resend e-mail.</Text>
          </Box>
        </UnstyledButton>}
      </div>

      <Modal
        opened={!!newOrder}
        onClose={() => setNewOrder(null)}
        size="calc(80vw)"
        withCloseButton={false}
      >
        <NewJobPage onClose={() => setNewOrder(null)} />
      </Modal>

      <Paper
        shadow="xs"
        ref={jobRef}
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 100,
          transition: '0.6s all',
          left: orderId ? (isMobile ? 0 : '55vw') : '100vw',
          background: 'white',
          borderRadius: 0,
          borderColor: '#DFDFDF',
          display: 'flex',
          flexDirection: 'column',
          // overflowY: 'auto',
          padding: 0,
        }}
      >
        <JobPage orderId={orderId} />
      </Paper>
    </AppShell.Main>
  </AppShell>
};

export default Layout;
