import React, { useEffect } from 'react';
import { ActionIcon, Badge, Box, Button, Card, Center, Checkbox, Divider, Grid, Group, Loader, Modal, Paper, Progress, Tabs, Text, Title, UnstyledButton } from '@mantine/core';
import { useAuth } from '../contexts/auth.context';
import SimpleHeader from '../components/simple_header';
import { FaArrowLeft, FaArrowRight, FaPlus } from "react-icons/fa";
import { MdOutlineGroups2 } from "react-icons/md";
import { GET_ALL_SUPPORTS } from '../services/supports';
import { useClipboard, useMediaQuery } from '@mantine/hooks';
import { getExtenseDatetime } from '../utility/util';
import { useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import SupportPage from './support';

export default function SupportsPage({ adding = false, onClose = null }) {
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [supports, setSupports] = React.useState<any[]>([]);
    const [selectedSupport, setSelectedSupport] = React.useState<any>(null);
    const [selectedStatus, setSelectedStatus] = React.useState<string>("pendent");

    const isMobile = useMediaQuery('(max-width: 900px)');

    const { role } = useAuth();
    const navigate = useNavigate();

    const loadData = () => {
        setLoading(true)
        GET_ALL_SUPPORTS()
        .then(items => {
            setLoading(false)
            setSupports(items)
        })
        .catch(err => {
            setLoading(false)
            notifications.show({message: err.message, color: 'red'})
        })
    }

    const renderItem = (item, index) => {
        if(role?.profile?.title === "Client") return <UnstyledButton onClick={() => navigate(`/support/${item._id}`)} style={{width: '100%'}}>
            <Paper p="lg" shadow='xs' mb="xl">
                <Group pl="lg" pr="lg">
                    <Box style={{flex: 1}}>
                        <Text size="sm" c="gray">{getExtenseDatetime(item.createdAt)}</Text>
                        <Text size="md" fw="bold">{item.category?.title || "Default Support"}</Text>
                        <Text size="xs" c="gray">{item.title}</Text>
                    </Box>
                    {renderBadge(item)}
                </Group>
            </Paper>
        </UnstyledButton>
    }

    const renderBadge = item => <Badge
        color={{
            "pendent": "yellow",
            "solved": "green"
        }[item.status] || "yellow"}
    >{{
        "pendent": "Active",
        "solved": "Solved"
    }[item.status] || "Active"}</Badge>

    const renderItems = (statuses = []) => <div style={{overflowY: "auto"}}>
        {loading && <Loader />}
        {supports
            .filter(s => statuses.includes(s.status))
            .map(support => <UnstyledButton style={{width: '100%'}} onClick={() => setSelectedSupport(support)}>
            <Group
                style={{borderLeft: `4px solid ${selectedSupport?._id === support._id ? "black" : "white"}`}}
                p="xl"
            >
                <Box style={{flex: 1}}>
                    <Text size="md" fw="bold">{support.category?.title || "Default Support"}</Text>
                    <Text size="sm" c="gray">{support.title}</Text>
                </Box>
                {renderBadge(support)}
            </Group>
            <Divider />
        </UnstyledButton>)}
    </div>

    useEffect(() => {
        loadData();
    }, [role])

    useEffect(() => {
        if(!!adding) setOpen(true);
    }, [adding]);
    
    useEffect(() => {
        if(!open) onClose && onClose();
    }, [open]);

    return <>
        <SimpleHeader
            title="Support"
            subtitle="Get your queries sorted."
            button={role?.profile?.title === "Client"
                ? {title: "Ask Anything", leftSection: <FaPlus />, style: { backgroundColor: 'black' }, onClick: () => navigate("/support/new")}
                : {title: "Open Support", onClick: () => navigate("/support/new")}}
        />
        
        {
            role?.profile?.title === "Client"
            ? supports.map(renderItem)
            : <Paper shadow='xs' style={{background: '#F4F4F4'}} mb="lg">
                <Grid>
                    {(!isMobile || !selectedSupport) && <Grid.Col span={{ base: 12, md: 5 }} style={{minHeight: '70vh'}}>
                        <Paper style={{height: '100%', border: 0}}>
                            <Tabs value={selectedStatus} onChange={setSelectedStatus} styles={{tabLabel: {padding: 20}}}>
                                <Tabs.List grow>
                                    <Tabs.Tab value="pendent">Open</Tabs.Tab>
                                    <Tabs.Tab value="solved">Closed</Tabs.Tab>
                                </Tabs.List>
                                <Tabs.Panel value='pendent'>{renderItems(["pendent", undefined, null])}</Tabs.Panel>
                                <Tabs.Panel value='solved'>{renderItems(["solved"])}</Tabs.Panel>
                            </Tabs>
                        </Paper>
                    </Grid.Col>}
                    {(!isMobile || selectedSupport) && <Grid.Col span={{ base: 12, md: 7 }} style={{height: '100%'}}>
                        {isMobile && <Group p="md">
                            <ActionIcon size="xl" onClick={() => setSelectedSupport(null)}><FaArrowLeft /></ActionIcon>
                        </Group>}
                        {selectedSupport
                        ? <div>
                            <SupportPage
                                supportId={selectedSupport._id}
                                onSave={loadData}
                                onClose={() => setSelectedSupport(null)}
                            />
                        </div>
                        : <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '70vh'}}>
                            <Title order={4} c="orange">Select a ticket to see details</Title>    
                        </div>}
                    </Grid.Col>}
                </Grid>
            </Paper>
        }

    </>
}
