import { existsSync } from 'fs';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'

// const availableLanguages = ['en-US', 'pt-BR'];
const availableLanguages = ['en-US'];

interface LanguageContextProps{
    lang: string;
    setLang: (lang: string) => void;
    str: (key: string) => string;
}

const LanguageContext = createContext<LanguageContextProps>({} as LanguageContextProps);

export const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
    const localLang = localStorage.getItem("@retainr/language");
    const defaultStrings = require(`../strings/en-US.json`);
    const [lang, setLang] = useState<string>(localLang ? localLang : navigator.language);
    const [strings, setStrings] = useState({});

    const str = (key: string, isDefault = false) => {
        let res = isDefault ? defaultStrings : strings;
        key.split(".").forEach(k => { res = {...res}[k] })
        return res ? res.toString() : isDefault ? key : str(key, true);
    }
    
    const value = useMemo(() => ({
        lang,
        setLang,
        str,
    }), [lang, strings])

    useEffect(() => {
        if(!availableLanguages.includes(lang)) setLang("en-US");
    }, [lang])
    
    useEffect(() => {
        localStorage.setItem("@retainr/language", lang);
    }, [lang])
    
    useEffect(() => {
        try{
            setStrings(require(`../strings/${lang}.json`));
        }catch(err){
            setStrings(defaultStrings)
        }
    }, [lang])

    return <LanguageContext.Provider value={value}>
        {children}
    </LanguageContext.Provider>
}

export const useLanguage = () => useContext(LanguageContext);