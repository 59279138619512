import { api } from "./api"

export const GET_ALL_JOBS = async () => {
    const {data} = await api.get(`/jobs/all`)
    return data;
}

export const GET_JOB_DETAILS = async (jobId: string) => {
    const {data} = await api.get(`/jobs/${jobId}/details`)
    return data;
}

export const GET_JOB_STATUS_OPTIONS = async () => {
    const {data} = await api.get(`/jobs/status/options`)
    return data;
}

export const DELETE_JOB = async (jobId) => {
    const {data} = await api.delete(`/jobs/${jobId}`)
    return data;
}

export const ARCHIVE_JOB = async (jobId, archive = true) => {
    const {data} = await api.patch(`/jobs/${jobId}/archive`, { archive })
    return data;
}

export const ACCEPT_JOB = async (jobId, params) => {
    const {data} = await api.patch(`/jobs/${jobId}/accept`, params)
    return data;
}

export const CONFIRM_PAYMENT_JOB = async (jobId, paymentData) => {
    const {data} = await api.patch(`/jobs/${jobId}/confirm/payment`, paymentData)
    return data;
}

export const UPDATE_PAYMENT_LINK_JOB = async (jobId, link) => {
    const {data} = await api.patch(`/jobs/${jobId}/payment/link`, { link })
    return data;
}

export const DECLINE_JOB = async (jobId) => {
    const {data} = await api.patch(`/jobs/${jobId}/decline`)
    return data;
}

export const CHANGE_JOB_RESPONSIBLE = async (jobId, userId, active) => {
    const {data} = await api.patch(`/jobs/${jobId}/responsibles/${userId}`, { active })
    return data;
}

export const CHANGE_JOB_CUSTOMER = async (jobId, userId) => {
    const {data} = await api.patch(`/jobs/${jobId}/customer/${userId}`)
    return data;
}

export const SEND_JOB_MESSAGE = async (jobId, { text, files = [] }) => {
    const formData = new FormData();
    files.forEach(file => { formData.append('files', file) });
    if(text) formData.append("text", text);
    
    const {data} = await api.post(`/jobs/${jobId}/message`, formData)
    return data;
}

export const MARK_JOB_VIEW = async (jobId, approver) => {
    const {data} = await api.patch(`/jobs/${jobId}/view`, { approver })
    return data;
}

export const SAVE_JOB = async (params) => {
    const {data} = await api.post(`/jobs/save`, params)
    return data;
}

export const SAVE_JOB_STATUS = async (jobId: string, statusId: string) => {
    const {data} = await api.patch(`/jobs/${jobId}/status/${statusId}`)
    return data;
}

export const APPROVE_TASK = async (taskId: string, approver) => {
    const {data} = await api.patch(`/jobs/tasks/${taskId}/approve`, {approver})
    return data;
}

export const APPROVE_JOB = async (jobId: string, approver) => {
    const {data} = await api.patch(`/jobs/${jobId}/approve`, {approver})
    return data;
}

export const ADJUSTMENT_JOB = async (jobId: string, text, approver) => {
    const {data} = await api.patch(`/jobs/${jobId}/adjustment`, { text, approver })
    return data;
}

export const ADJUSTMENT_TASK = async (taskId: string, text, approver) => {
    const {data} = await api.patch(`/jobs/tasks/${taskId}/adjustment`, { text, approver })
    return data;
}

export const PUBLIC_SAVE_TASK = async (taskId: string, approver, params) => {
    const {data} = await api.patch(`/jobs/tasks/${taskId}/save`, {...params, approver})
    return data;
}

export const SAVE_JOB_TASK = async (params) => {
    const {data} = await api.post(`/jobs/tasks/save`, params)
    return data;
}
