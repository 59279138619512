import React, { useEffect, useRef, useState } from 'react'
import { Image, Loader } from '@mantine/core'

export default function RenderImage(props){
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [renderSize, setRenderSize] = useState({ width: 0, height: 0 });
    const [loading, setLoading] = useState(false);

    const parentRef = useRef<any>();

    const handleImageLoad = (event) => {
        const { naturalWidth, naturalHeight } = event.target;
        setImageSize({ height: naturalHeight, width: naturalWidth });
        setLoading(false);
    }

    useEffect(() => {
        const containerWidth = parentRef.current.offsetWidth;
        let height = props.height ?? 200;
        let width = props.width || containerWidth;
        width = (imageSize.width && imageSize.width < width) ? imageSize.width : width;
        setRenderSize({ width, height })
    }, [props, imageSize, parentRef.current])

    return <div ref={parentRef} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative'}}>
        {loading && <div style={{position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, background: '#EFEFEF', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 200, minWidth: '100%'}}>
            <Loader />
        </div>}
        <Image
            src={props.src}
            height={renderSize.height}
            width={renderSize.width}
            fit={props.fit ?? 'contain'}
            onLoadStart={() => setLoading(true)}
            onLoad={handleImageLoad}
            style={{visibility: loading ? "hidden" : "visible", ...props.style}}
        />
    </div>
}