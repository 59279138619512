import { api } from "./api"

export const GET_ALL_FORMS = async () => {
    const {data} = await api.get("/forms")
    return data.forms || [];
}

export const GET_FORM_DETAILS = async (id) => {
    const {data} = await api.get(`/forms/${id}/defails`)
    return data;
}

export const APPLY_FORM = async (id, params) => {
    const {data} = await api.post(`/forms/${id}/apply`, params)
    return data;
}

export const DELETE_FORM = async (id) => {
    const {data} = await api.delete(`/forms/${id}`)
    return data;
}

export const SAVE_FORM = async (params : any) => {
    const {data} = await api.post(`/forms`, params)
    return data;
}
