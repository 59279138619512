import { useEffect, useState } from 'react';
import lamejs from 'lamejs'
import { UPLOAD_FILES } from '../services/files';

const useRecorder = () => {
    const [recording, setRecording] = useState(false);
    const [audio, setAudio] = useState('');
    const [audioBlob, setAudioBlob] = useState<any>(null);
    const [timeCounter, setTimeCounter] = useState(0);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>(null);
    const [audioStream, setAudioStream] = useState(null);

    useEffect(() => {
        let interval = null;
        if (recording) {
            setTimeCounter(0);
            interval = setInterval(() => { setTimeCounter(prevCounter => prevCounter + 300); }, 300);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [recording]);

    const startRecording = () => {        
        navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
            const mediaRecorder = new MediaRecorder(stream);
            const audioChunks : any[] = [];

            mediaRecorder.addEventListener("dataavailable", event => {
                audioChunks.push(event.data);
            });

            mediaRecorder.addEventListener("start", event => {
                setRecording(true);
            });

            mediaRecorder.addEventListener("stop", async () => {
                setRecording(false);
                const audioBlob = new Blob(audioChunks, {type: "audio/mpeg"});
                const audioUrl = URL.createObjectURL(audioBlob);
                setAudioBlob(audioBlob);
                setAudio(audioUrl);
                stream.getTracks().forEach(track => track.stop());
            });

            setMediaRecorder(mediaRecorder);
            mediaRecorder.start();
        });
    };

    const saveAudio = () : Promise<{url}> => {
        return new Promise((resolve, reject) => {
            // resolve({url: "https://www.buildquickbots.com/whatsapp/media/sample/audio/sample01.mp3"})
            UPLOAD_FILES([audioBlob])
            .then((files) => {
                if(!files[0]?.url) throw new Error("Falha ao enviar áudio");
                resolve({url: files[0]?.url})
            })
            .catch(error => {
                reject(error);
            });
        })
    };

    const stopRecording = () => {
        mediaRecorder && mediaRecorder.stop();
    }

    const clear = () => {
        stopRecording();
        setRecording(false);
        setAudio(null);
        setAudioBlob(null);
    }
    
    return { startRecording, stopRecording, timeCounter, recording, audio, saveAudio, clear }
};

export { useRecorder };
