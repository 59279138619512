import { Group, Text } from '@mantine/core';
import * as React from 'react';
import { useAuth } from "../contexts/auth.context";
import InputField, { InputFieldTypes } from './input_field';
import { useNavigate } from 'react-router-dom';

export default function RolesSelect({ onChange = null }){
    const { role, roles, onChangeRole } = useAuth();
    const navigate = useNavigate();

    return role && (
      (roles.length > 1) ? 
        <InputField
          fieldType={InputFieldTypes.SELECT}
          options={roles.map(r => ({label: `${r.company.name}`, value: r._id}))}
          value={role?._id}
          name="roleId"
          clearable={false}
          onChange={({roleId}) => {
            navigate("/")
            onChange && onChange(roleId);
            onChangeRole(roleId)
          }}
        />
      : <Text size="sm" p="xs">{role?.company?.name} ({role?.profile?.title})</Text>
    )
}