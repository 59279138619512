import { Button, Grid, Group, Paper } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { Service } from "../components/service";
import { SAVE_PLAN, GET_ALL_PLANS, DELETE_PLAN } from "../services/plans";
import InputField, { InputFieldTypes } from "../components/input_field";
import { notifications } from "@mantine/notifications";
import { useApp } from "../contexts/app.context";

export default function PlansPage(){
    const [plans, setPlans] = useState<any[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<any>(null);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const { confirmDialog } = useApp();

    const loadPlans = () => {
        GET_ALL_PLANS()
        .then(res => {
            setPlans(res);
        })
        .catch(err => {
            notifications.show({ message: err.message, color: "red" })
        })
    }
    
    const handleSaveService = (res) => {
        let params = { ...selectedPlan, service: selectedPlan?.service?._id || res._id };

        setLoadingSave(true)
        SAVE_PLAN(params)
        .then((res) => {
            loadPlans();
            setSelectedPlan(res)
            setLoadingSave(false);
        })
        .catch(err => {
            setLoadingSave(false);
        })
    }

    useEffect(loadPlans, []);
    
    return <div style={{position: 'relative'}}>
        <Grid>
            <Grid.Col span={{ base: 12, md: 12 }}>
                <Group mb="md">
                    <InputField
                        name="pl"
                        style={{flex: 1}}
                        onChange={({pl}) => {
                            pl === "new"
                            ? setSelectedPlan({  })
                            : setSelectedPlan(plans.find(p => p._id === pl))
                        }}
                        value={selectedPlan ? selectedPlan?._id || "new" : null}
                        fieldType={InputFieldTypes.SELECT}
                        clearable={false}
                        searchable={false}
                        options={[
                            ...plans.map(p => ({label: p.service?.plan_name, value: p._id})),
                            { value: "new", label: "Add new plan" }
                        ]}
                    />
                    {selectedPlan?._id && <Button color="gray" variant="outline"
                        onClick={() => {
                            confirmDialog(
                                { text: "Do you realy wants to delete this plan?" },
                                ({ confirmed }) => {
                                    if(confirmed) DELETE_PLAN(selectedPlan?._id)
                                    .then(() => {
                                        loadPlans();
                                        setSelectedPlan(null);
                                    })
                                    .catch(err => {
                                        notifications.show({ message: err.message })
                                    })
                                }
                            )
                        }}
                    >Delete plan</Button>}
                </Group>

                {selectedPlan && <>
                    <Paper p="md" mb="md">
                        <InputField
                            name="mobile_app"
                            fieldType={InputFieldTypes.CHECKBOX}
                            onChange={({mobile_app}) => setSelectedPlan(sp => ({...sp, mobile_app}))}
                            value={selectedPlan?.mobile_app}
                            title="Mobile App"
                        />
                        <InputField
                            name="max_seats"
                            mt="md"
                            fieldType={InputFieldTypes.NUMBER}
                            onChange={({max_seats}) => setSelectedPlan(sp => ({...sp, max_seats}))}
                            value={selectedPlan?.max_seats}
                            title="Max. Seats"
                        />
                    </Paper>
                    <Paper p="md">
                        <Service for_plan={true} data={selectedPlan.service} onSave={handleSaveService} />
                    </Paper>
                </>}
            </Grid.Col>
        </Grid>
    </div>
}
