import { ActionIcon, Badge, Box, Button, Divider, Grid, Group, Loader, Menu, Modal, Paper, Switch, Tabs, Text, Title, Tooltip, UnstyledButton } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaCopy, FaEllipsisV, FaPlus, FaPlusCircle, FaShare, FaShareAlt, FaTimesCircle } from 'react-icons/fa';
import { Service } from '../components/service';
import SimpleHeader from '../components/simple_header';
import { GET_ALL_SERVICES, UPDATE_SERVICE, SAVE_SERVICES_ORDER } from '../services/services';
import { DELETE_FORM, GET_ALL_FORMS, SAVE_FORM } from '../services/forms';
import { useClipboard, useMediaQuery } from '@mantine/hooks';
import { useAuth } from '../contexts/auth.context';
import InputField, { InputFieldTypes } from '../components/input_field';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { formatLabel } from '../utility/util';

export default function ServicesPage(){
    const [loading, setLoading] = useState(false);
    const [updatingService, setUpdatingService] = useState(null);
    const [services, setServices] = useState<any[]>([]);
    const [forms, setForms] = useState<any[]>([]);
    const [updatingForm, setUpdatingForm] = useState<any>(null);
    const [selectedStatus, setSelectedStatus] = useState<string | null>("active");

    const isMobile = useMediaQuery('(max-width: 900px)');
    const clipboard = useClipboard();
    const { userData } = useAuth();

    const onSave = (service) => {
        if(!updatingService?._id) setUpdatingService({ _id: service._id })
        loadServices();
    }

    const loadForms = () => {
        GET_ALL_FORMS()
        .then(res => {
            setForms(res)
        })
        .catch(err => {
            notifications.show({message: err.message, color: 'red'})
        })
    }

    const changeServiceStatus = (serviceId, status = "active") => {
        UPDATE_SERVICE(serviceId, { status })
        .then(() => {
            loadServices();
        })
        .catch(err => {
            notifications.show({message: err.message, color: 'red'})
        })
    }
    
    const deleteForm = (formId) => {
        DELETE_FORM(formId)
        .then(() => {
            loadForms();
        })
        .catch(err => {
            notifications.show({message: err.message, color: 'red'})
        })
    }
    
    const handleSaveForm = () => {
        SAVE_FORM(updatingForm)
        .then(() => {
            loadForms();
            setUpdatingForm(null);
        })
        .catch(err => {
            notifications.show({message: err.message, color: 'red'})
        })
    }

    const handleDragEnd = (result) => {
        if (!result.destination) return; // Dragged outside the list, ignore
      
        const previous = [...services];
        const newServices = [...services];
        const [reorderedItem] = newServices.splice(result.source.index, 1);
        newServices.splice(result.destination.index, 0, reorderedItem);

        SAVE_SERVICES_ORDER(newServices.map(s => s._id))
        .then(res => {})
        .catch(err => {
            setServices(previous);
        })
        
        setServices(newServices);
    };

    const renderItems = status => <div style={{overflowY: "auto"}}>
        {loading && <Loader size="md" m="lg" />}
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="directions">
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {services.map((service, i) => {
                            return <Draggable key={i} draggableId={i.toString()} index={i}>
                                {(provided) => 
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <Group
                                    style={{
                                        display: status === "all" || service.status === status ? "flex" : "none",
                                        background: 'white',
                                        borderLeft: `4px solid ${updatingService?._id === service._id ? "black" : "white"}`}}
                                    p="xl"
                                >
                                    <Box style={{flex: 1}}>
                                        <Text>{service.plan_name}</Text>
                                        <Group>
                                            {
                                                service.pricing_table
                                                ? <Text c="gray">{(service.table ?? []).length} item(s) at pricing table</Text>
                                                : service.pricing_structure === "custom"
                                                ? <Text c="gray">On Demand (USD)</Text>
                                                : <Text c="gray">{service.currency} {(service.unity_price ?? 0).toFixed(2)}</Text>
                                            }
                                            <Badge color={service.status === "active" ? "green" : "red"}>
                                                {service.status === "active" ? "active" : "inactive"}
                                            </Badge>
                                        </Group>
                                    </Box>
                                    <Menu>
                                        <Menu.Target><ActionIcon variant='light' color="gray"><FaEllipsisV /></ActionIcon></Menu.Target>
                                        <Menu.Dropdown>
                                            <Menu.Item
                                                onClick={() => {
                                                    clipboard.copy(`https://${userData?.company?.defaultDomain}/${formatLabel(service.label ?? service.plan_name ?? "")}`);
                                                    notifications.show({ message: "Copied to clipboard" })
                                                }}
                                            >
                                                <Box>
                                                    <Text size="sm">Magic Link</Text>
                                                    <Text size="xs" c="gray">Share this order with your clients using a magic link to onboard them</Text>
                                                </Box>
                                            </Menu.Item>
                                            <Menu.Divider />
                                            <Menu.Item color="gray" onClick={() => setUpdatingService({...service})}>Edit</Menu.Item>
                                            <Menu.Divider />
                                            <Menu.Item color={service.status === "active" ? "red" : "green"} onClick={() => changeServiceStatus(service._id, service.status === "active" ? "inactive" : "active")}>{service.status === "active" ? "Inactivate" : "Activate"}</Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                </Group>
                                <Divider />
                                    </div>
                                }
                            </Draggable>
                        })}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    </div>
    
    const renderForms = () => <div style={{overflowY: "auto"}}>
        {loading && <Loader />}
        {forms.length === 0 && <Group style={{flexDirection: 'column'}} p="xl">
            <Text c="gray" ta="center" size="xs">No form registered yet</Text>
            <Button size="xs" onClick={() => setUpdatingForm({})}>Create First</Button>
        </Group>}
        {forms.map(form => <>
            <Group
                style={{borderLeft: `4px solid ${updatingService?._id === form._id ? "black" : "white"}`}}
                p="xl"
            >
                <Box style={{flex: 1}}>
                    <Text>{form.title}</Text>
                    <Group>
                        <Text c="gray"></Text>
                    </Group>
                </Box>
                <Menu>
                    <Menu.Target><ActionIcon variant='light' color="gray"><FaEllipsisV /></ActionIcon></Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item color="gray" onClick={() => setUpdatingForm({...form})}>Edit</Menu.Item>
                        <Menu.Item color={"red"} onClick={() => deleteForm(form._id)}>Delete</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </Group>
            <Divider />
        </>)}
    </div>

    const loadServices = () => {
        setLoading(true);
        GET_ALL_SERVICES()
        .then(res => {
            setServices(res);
            setLoading(false);
            if(updatingService?._id) setUpdatingService(res.find(r => r._id === updatingService._id))
        })
        .catch(err => { 
            setLoading(false)
            notifications.show({message: err.message, color: "red"})
        })
    }

    useEffect(() => {
        loadServices();
        loadForms();
    }, []);

    return <>
        <SimpleHeader
            title="Services"
            subtitle="Create and Manage your service packages."
            buttons={[
                {title: "Add Form", variant: "outline", leftSection: <FaPlus />, onClick: () => setUpdatingForm({})},
                {title: "Add Package", leftSection: <FaPlus />, onClick: () => setUpdatingService({})},
            ]}
        />

        <Paper shadow='xs' style={{padding: 0, background: '#FFFFFF'}} mb="lg">
            <Grid>
                {(!isMobile || !updatingService) && <Grid.Col span={{ base: 12, md: 5 }} style={{paddingTop: 0, paddingBottom: 0, minHeight: '70vh'}}>
                    <Paper style={{height: '100%', border: 0}}>
                        <Tabs value={selectedStatus} onChange={setSelectedStatus} styles={{tabLabel: {padding: 20}}}>
                            <Tabs.List grow>
                                <Tabs.Tab value="active">Active</Tabs.Tab>
                                {/* <Tabs.Tab value="draft">Draft</Tabs.Tab> */}
                                <Tabs.Tab value="inactive">Inactive</Tabs.Tab>
                                <Tabs.Tab value="all">All</Tabs.Tab>
                                <Tabs.Tab value="forms">Forms</Tabs.Tab>
                            </Tabs.List>
                            <Tabs.Panel value='active'>{renderItems("active")}</Tabs.Panel>
                            {/* <Tabs.Panel value='draft'>{renderItems("draft")}</Tabs.Panel> */}
                            <Tabs.Panel value='inactive'>{renderItems("inactive")}</Tabs.Panel>
                            <Tabs.Panel value='all'>{renderItems("all")}</Tabs.Panel>
                            <Tabs.Panel value='forms'>{renderForms()}</Tabs.Panel>
                        </Tabs>
                    </Paper>
                </Grid.Col>}
                {(!isMobile || updatingService) && <Grid.Col span={{ base: 12, md: 7 }} style={{height: '100%'}}>
                    {isMobile && <Group p="md">
                        <ActionIcon size="xl" onClick={() => setUpdatingService(null)}><FaArrowLeft /></ActionIcon>
                    </Group>}
                
                    {updatingService
                    ? <Box p="xl">
                        <Service onSave={onSave} data={updatingService} forms={forms} />
                    </Box>
                    : <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '70vh'}}>
                        <Title order={4} c="orange">Select a service to see details</Title>    

                        <Button variant='outline' color="orange" mt="md" leftSection={<FaPlus />} onClick={() => setUpdatingService({})}>Add new</Button>
                    </div>}
                </Grid.Col>}
            </Grid>
        </Paper>

        <Modal
            opened={!!updatingForm}
            onClose={() => setUpdatingForm(null)}
            size="xl"
            title={updatingForm?._id ? "Edit Form" : "New Form"}
        >
            <InputField
                name="title"
                mb="md"
                placeholder='Form Title'
                value={updatingForm?.title}
                onChange={({title}) => setUpdatingForm(uf => ({ ...uf, title }))}
            />
            <InputField
                name="description"
                mb="md"
                placeholder='Description or instructions to form application'
                value={updatingForm?.description}
                fieldType={InputFieldTypes.TEXTAREA}
                onChange={({description}) => setUpdatingForm(uf => ({ ...uf, description }))}
            />

            {
                (updatingForm?.questions ?? [{ type: "simple" }]).map((question, qi) => <Paper mb="20px" p="20px" style={{border: '1px solid #DFDFDF', borderRadius: 10}}>
                    <Group>
                        <InputField
                            name="title"
                            style={{flex: 1}}
                            placeholder='Title'
                            value={question?.title}
                            onChange={({title}) => {
                                setUpdatingForm(uf => ({
                                    ...uf,
                                    questions: ((uf.questions ?? []).length - 1) >= qi
                                        ? uf.questions.map((q, qj) => qi === qj ? { ...q, title } : q)
                                        : [...(uf.questions ?? []), { title, type: "simple" }]
                                }))
                            }}
                        />
                        <InputField
                            name="type"
                            placeholder='Field Type'
                            fieldType={InputFieldTypes.SELECT}
                            value={question?.type}
                            searchable={false}
                            clearable={false}
                            onChange={({type}) => {
                                setUpdatingForm(uf => ({
                                    ...uf,
                                    questions: ((uf.questions ?? []).length - 1) >= qi
                                        ? uf.questions.map((q, qj) => qi === qj ? { ...q, type } : q)
                                        : [...(uf.questions ?? []), { type }]
                                }))
                            }}
                            options={[
                                { value: "simple", label: "Small Text" },
                                { value: "text", label: "Large Text" },
                                { value: "single-choice", label: "Single Choice" },
                                { value: "multiple-choice", label: "Multiple Choice" },
                                { value: "yes-no", label: "Yes / No" },
                                { value: "evaluation", label: "Evaluation" },
                                { value: "image", label: "Image" },
                                { value: "file", label: "File" },
                            ]}
                        />
                        <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Switch
                                onChange={() => {
                                    setUpdatingForm(uf => ({
                                        ...uf,
                                        questions: ((uf.questions ?? []).length - 1) >= qi
                                            ? uf.questions.map((q, qj) => qi === qj ? { ...q, required: !q.required } : q)
                                            : [...(uf.questions ?? []), { required: true, type: "simple" }]
                                    }))
                                }}
                                value={question?.required}
                                size="xs"
                            />
                            <Text ta="center" size="10px" c="gray">required</Text>
                        </Box>
                    </Group>
                    {
                        [
                            "multiple-choice",
                            "single-choice",
                        ].includes(question?.type) && <>
                            {(question?.options ?? [{}]).map((opt, oi) => <Group mt="md" align='center'>
                                {
                                    (question.options ?? []).length > 1 && <Tooltip label="Remove option">
                                        <ActionIcon
                                            variant='light'
                                            onClick={() => {
                                                setUpdatingForm(uf => ({
                                                    ...uf,
                                                    questions: uf.questions.map((q, qj) => qi === qj ? {
                                                        ...q,
                                                        options: [...q.options.slice(0,oi), ...q.options.slice(oi+1, q.options.length)]
                                                    } : q)
                                                }))
                                            }}
                                        ><FaTimesCircle /></ActionIcon>
                                    </Tooltip>
                                }
                                <InputField
                                    name="value"
                                    style={{flex: 1}}
                                    placeholder={`Option ${oi+1}`}
                                    value={opt?.value}
                                    onChange={({value}) => {
                                        setUpdatingForm(uf => ({
                                            ...uf,
                                            questions: uf.questions.map((q, qj) => qi === qj ? {
                                                ...q, options: (q.options ?? []).length - 1 >= oi
                                                    ? q.options.map((o, oj) => oi === oj ? { ...o, value } : o)
                                                    : [{ value }]
                                            } : q)
                                        }))
                                    }}
                                />
                                {
                                    <Tooltip label="Add option">
                                        <ActionIcon
                                            style={{visibility: (question.options ?? []).length === 0 || oi >= (question.options ?? []).length - 1 ? 'visible' : 'hidden'}}
                                            onClick={() => {
                                                setUpdatingForm(uf => ({
                                                    ...uf,
                                                    questions: uf.questions.map((q, qj) => qi === qj ? {
                                                        ...q, options: (q.options ?? []).length - 1 >= oi ? [...q.options, {}] : [{}, {}]
                                                    } : q)
                                                }))
                                            }}
                                        ><FaPlusCircle /></ActionIcon>
                                    </Tooltip>
                                }
                            </Group>)}
                        </>
                    }
                </Paper>)
            }

            <Group justify='space-between' mt="xl">
                <Button
                    onClick={() => {
                        setUpdatingForm(uf => ({
                            ...uf,
                            questions: (uf.questions ?? []).length > 0
                                ? [...uf.questions, { type: "simple" }]
                                : [{ type: "simple" }, { type: "simple" }]
                        }))
                    }}
                    variant="outline" size="md" leftSection={<FaPlus />}>Add Question</Button>
                <Button size="md" onClick={handleSaveForm}>Save</Button>
            </Group>
        </Modal>
    </>
}