import { Box, Button, Group, Loader, Table as MantineTable } from '@mantine/core';
import React from 'react';
import { ActionProps, ActionsRender } from '../components/actions_render';

interface TableProps<T>{
    loading? : boolean;
    error? : string;
    data: T[];
    leftIcon?: any;
    columns: {key:string; title:string; render?: (item:T, index: number) => any}[];
    actions?: ActionProps[];
    [key: string]: any;
}
export default function Table(props : TableProps<any>){
    const {actions = [], columns = [], loading = false, error, ...others} = props; 
    if(error) return <Group style={{color: "#9a9a9a", padding: '40px 0'}}>Falha ao carregar: {error}</Group>;
    
    return <>
        <div style={{padding: '10px 0'}}>
            {loading && <Loader />}
        </div>
        <MantineTable.ScrollContainer minWidth={100}>
            <MantineTable
                // {...others}
                verticalSpacing="xs"
                striped
                highlightOnHover
                style={{width: '100%'}}
            >
                <MantineTable.Thead>
                    <MantineTable.Tr>
                        {columns.map(column=> <MantineTable.Th key={column.key} style={{whiteSpace: 'nowrap', minWidth: 50}}>{column.title}</MantineTable.Th>)}
                        {actions.length > 0 && <MantineTable.Th style={{width: 50}}></MantineTable.Th>}
                    </MantineTable.Tr>
                </MantineTable.Thead>
                <MantineTable.Tbody>
                    {props.data.map((item, index) => <MantineTable.Tr key={`${index}`} style={{backgroundColor: 'white'}}>
                        {columns.map(column => <MantineTable.Td>{column.render ? column.render(item, index) : item[column.key]}</MantineTable.Td>)}
                        {actions.length > 0 && <MantineTable.Td><ActionsRender
                            actions={actions}
                            item={item}
                            index={index}
                        /></MantineTable.Td>}
                    </MantineTable.Tr>
                    )}
                </MantineTable.Tbody>
            </MantineTable>
        </MantineTable.ScrollContainer>
    </>
}