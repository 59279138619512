import { ActionIcon, Button, Card, Checkbox, Divider, Grid, Group, Loader, Paper, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React, { useEffect, useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import InputField from '../components/input_field';
import { useApp } from '../contexts/app.context';
import { useAuth } from '../contexts/auth.context';
import { GET_COMPANY_PREFERENCES, UPDATE_COMPANY_PREFERENCES } from '../services/companies';

export default function PreferencesPage(){
    const { user: currentUser, role } = useAuth();
    const { expandText } = useApp();

    const [preferences, setPreferences] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const loadPreferences = () => {
        setLoading(true);
        GET_COMPANY_PREFERENCES()
        .then(res => {
            setLoading(false);
            setPreferences(res);
        })
        .catch(err => {
            setLoading(false);
            notifications.show({message: err.message, color: 'red'})
        })
    }

    const handleSave = () => {
        setLoadingSave(true);
        UPDATE_COMPANY_PREFERENCES({...preferences})
        .then(() => {
            setLoadingSave(false);
            loadPreferences();
        })
        .catch(err => {
            setLoadingSave(false);
            notifications.show({message: err.message, color: 'red'})
        })
    }

    useEffect(loadPreferences, [])
    useEffect(() => console.log(preferences), [preferences])

    return <>
        <Paper p="md" shadow='xs'>
            <Title order={4}>Notificações</Title>

            <Grid pt="md" pb="md">
                <Grid.Col span={{ base: 12, md: 4 }}>
                    <Card shadow='xs'>
                        <Group>
                            <Title style={{flex: 1}} order={5}>Solicitação de aprovação</Title>
                            <ActionIcon color="yellow" onClick={() => expandText("Notificações enviadas ao cliente assim que enviado para aprovação")}><FaExclamationCircle /></ActionIcon>
                        </Group>
                        <Divider mt="md" mb="md" />
                        <Checkbox mb="md" label="E-mail" checked={preferences?.notifications?.approve_request?.email} onChange={(e) => {
                            setPreferences(p => ({...p, notifications: {...p?.notifications, approve_request: {...p?.notifications?.approve_request, email: e.target.checked}}}))
                        }} />
                        <Checkbox mb="md" label="Whatsapp" checked={preferences?.notifications?.approve_request?.whatsapp} onChange={(e) => {
                            setPreferences(p => ({...p, notifications: {...p?.notifications, approve_request: {...p?.notifications?.approve_request, whatsapp: e.target.checked}}}))
                        }} />
                    </Card>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4 }}>
                    <Card shadow='xs'>
                        <Group>
                            <Title style={{flex: 1}} order={5}>Solicitação de ajuste</Title>
                            <ActionIcon color="yellow" onClick={() => expandText("Notificações enviadas aos usuários assim que o cliente solicita ajuste")}><FaExclamationCircle /></ActionIcon>
                        </Group>
                        <Divider mt="md" mb="md" />
                        <Checkbox mb="md" label="E-mail" checked={preferences?.notifications?.adjust_request?.email} onChange={(e) => {
                            setPreferences(p => ({...p, notifications: {...p?.notifications, adjust_request: {...p?.notifications?.adjust_request, email: e.target.checked}}}))
                        }} />
                        <Checkbox mb="md" label="Whatsapp" checked={preferences?.notifications?.adjust_request?.whatsapp} onChange={(e) => {
                            setPreferences(p => ({...p, notifications: {...p?.notifications, adjust_request: {...p?.notifications?.adjust_request, whatsapp: e.target.checked}}}))
                        }} />
                    </Card>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4 }}>
                    <Card shadow='xs'>
                        <Group>
                            <Title style={{flex: 1}} order={5}>Lembretes de aprovação</Title>
                            <ActionIcon color="yellow" onClick={() => expandText("Lembrete enviado ao cliente dias antes da deadline")}><FaExclamationCircle /></ActionIcon>
                        </Group>
                        <Divider mt="md" mb="md" />
                        <InputField
                            name="n"
                            title="Dias antes da deadline"
                            type="number"
                            mb="md"
                            value={preferences?.notifications?.approve_remember?.days_before}
                            onChange={({n}) => {
                                setPreferences(p => ({...p, notifications: {...p?.notifications, approve_remember: {...p?.notifications?.approve_remember, days_before: n}}}))
                            }}
                        />
                        <Checkbox mb="md" label="E-mail" checked={preferences?.notifications?.approve_remember?.email} onChange={(e) => {
                            setPreferences(p => ({...p, notifications: {...p?.notifications, approve_remember: {...p?.notifications?.approve_remember, email: e.target.checked}}}))
                        }} />
                        <Checkbox mb="md" label="Whatsapp" checked={preferences?.notifications?.approve_remember?.whatsapp} onChange={(e) => {
                            setPreferences(p => ({...p, notifications: {...p?.notifications, approve_remember: {...p?.notifications?.approve_remember, whatsapp: e.target.checked}}}))
                        }} />
                    </Card>
                </Grid.Col>
            </Grid>

            <Group justify="flex-end">
                <Button onClick={handleSave} loading={loadingSave || loading}>Submit</Button>
            </Group>
        </Paper>
    </>
}
