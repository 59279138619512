import { api } from "./api"

export const GET_REVENUE = async (params) => {
    const { data } = await api.get("/statistics/revenue", {params})
    return data;
}
export const GET_MEMBERS = async (params) => {
    const { data } = await api.get("/statistics/members", {params})
    return data;
}
export const GET_TASKS = async (params) => {
    const { data } = await api.get("/statistics/tasks", {params})
    return data;
}
export const GET_TOTALS = async (params) => {
    const { data } = await api.get("/statistics/totals", {params})
    return data;
}
