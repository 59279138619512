import { api } from "./api";

export const GET_PLAN_PAYMENTS = async () => {
    const {data} = await api.get(`/payments/plans`)
    return data;
}

export const GET_STRIPE_CONFIGS = async (origin : "retainr" | "company" = "retainr") => {
    const {data} = await api.get(`/payments/stripe/${origin}/configs`)
    return data;
}

export const SAVE_PAYMENT_METHOD = async (
    origin : "retainr" | "company" = "retainr",
    data,
    customer,
) => {
    const { data: dt } = await api.post(`/payments/${origin}/payment/method`, {
        data,
        customer,
        type: origin,
    })
    return dt;
}

export const REFUND_PAYMENT = async (id: string, value) => {
    const {data} = await api.post(`/payments/${id}/refund`, { value })
    return data;
}
