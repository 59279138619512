import { api } from "./api"

export const UPLOAD_FILES = async (files : any[] = []) => {
    const formData = new FormData();
    files.forEach(file => { formData.append('files', file) });
    const {data} = await api.post(`/files/upload`, formData)
    return data.files || [];
}

export const GET_ALL_FILES = async (files : any[] = []) => {
    const {data} = await api.get(`/files/all`)
    return data;
}
