import { api } from "./api"

export const GET_ALL_KNOWLEDGES = async () => {
    const {data} = await api.get("/knowledges")
    return data.knowledges || [];
}

export const GET_KNOWLEDGE_DETAILS = async (id) => {
    const {data} = await api.get(`/knowledges/${id}`)
    return data;
}

export const FINISH_KNOWLEDGE = async (id) => {
    const {data} = await api.patch(`/knowledges/${id}/finish`)
    return data;
}

export const DELETE_KNOWLEDGE = async (id) => {
    const {data} = await api.delete(`/knowledges/${id}`)
    return data;
}

export const SAVE_KNOWLEDGE = async (params : any) => {
    const {data} = await api.post(`/knowledges`, params)
    return data;
}

export const DELETE_KNOWLEDGE_CATEGORY = async (id) => {
    const {data} = await api.delete(`/knowledges/categories/${id}`)
    return data;
}

export const SAVE_KNOWLEDGE_CATEGORY = async (params : any) => {
    const {data} = await api.post(`/knowledges/categories`, params)
    return data;
}
