import { ActionIcon, Avatar, Box, Tooltip, Button, Divider, Group, Paper, Text, Title, UnstyledButton, LoadingOverlay } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../contexts/auth.context';
import { GET_CUSTOMER_DETAILS } from '../services/customers';
import { FaCalendarDay, FaTimes } from 'react-icons/fa';
import { useApp } from '../contexts/app.context';
import { DELETE_USER, GET_USER_DATA, UPDATE_USER } from '../services/users';
import { getExtenseDatetime } from '../utility/util';
import InputField from '../components/input_field';
import { JobKanban } from './job';

export default function CustomerPage({ adding = false, onClose = null }) {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [loadingSave, setLoadingSave] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any>(null);

    const isMobile = useMediaQuery('(max-width: 900px)');

    const { role } = useAuth();
    const { confirmDialog } = useApp();
    const { customerId } = useParams();
    const navigate = useNavigate();

    const newData = {
        name: data?.name,
        website: data?.website,
        phone: data?.phone,
        company_name: data?.company_name,
    }

    const loadData = () => {
        setLoading(true)
        GET_USER_DATA(customerId)
        .then(items => {
            setLoading(false)
            setData(items)
        })
        .catch(err => {
            setLoading(false)
            notifications.show({message: err.message, color: 'red'})
        })
    }
    
    const deleteClient = () => {
        DELETE_USER(customerId)
        .then(() => {
            navigate("/clients")
            notifications.show({message: "User deleted!"})
        })
        .catch(err => {
            notifications.show({message: err.message, color: 'red'})
        })
    }
    
    const handleSave = () => {
        setLoadingSave(true)
        UPDATE_USER(customerId, newData)
        .then(() => {
            setLoadingSave(false)
            notifications.show({message: "User updated"})
        })
        .catch(err => {
            setLoadingSave(false)
            notifications.show({message: err.message, color: 'red'})
        })
    }

    const handleChange = (dt) => setData(d => ({...d, ...dt}))

    useEffect(() => {
        loadData();
    }, [customerId])

    return <>
        <LoadingOverlay visible={loading} />
        <Paper shadow='xs' style={{padding: 0, display: 'flex', flexDirection: isMobile ? 'column' : 'row', minHeight: '80vh'}}>
            <Box p="xl" style={{flex: 0.6}}>
                <Group>
                    <Avatar src={data?.image} size={80} color="gray"  style={{border: '1px solid #EFEFEF'}} />
                    <Box style={{flex: 1}}>
                        <Title order={3}>{data?.name}</Title>
                        <Text size="sm" c="gray" mt="md"></Text>
                    </Box>
                </Group>
                <InputField mt="md" name="email" onChange={handleChange} value={data?.email} disabled title="Email" />
                <InputField mt="md" name="name" onChange={handleChange} value={data?.name} title="Name" />
                <InputField mt="md" name="phone" onChange={handleChange} value={data?.phone} title="Phone" />
                <InputField mt="md" name="company_name" onChange={handleChange} value={data?.company_name} title="Company / Brand" />
                <InputField mt="md" name="website" onChange={handleChange} value={data?.website} title="Website" />

                <Group justify='flex-end' mt="xl">
                    <Tooltip label="Delete This Client Profile">
                        <Button color="red" size="md" variant='outline' onClick={() => confirmDialog({text: "Do you realy wany to delete this client? All of his data gonna will be removed."}, ({confirmed}) => confirmed && deleteClient())}>Delete</Button>
                    </Tooltip>
                    <Button size="md" loading={loadingSave} onClick={handleSave}>Save Profile</Button>
                </Group>
            </Box>
            <Box style={{background: '#FAFAFA', flex: 0.4}}>
                <Group p="xl" justify="flex-end">
                    <ActionIcon size="lg" onClick={() => navigate("/clients")}><FaTimes /></ActionIcon>
                </Group>
                <Divider color="#E0E0E0" />
                <Box p="xl">
                    <Title order={4}>Orders History</Title>
                    {
                        (data?.jobs || []).length === 0 &&
                        <Text c="gray" mt="md" size="sm" ta='center'>No orders yet.</Text>
                    }
                    {
                        (data?.jobs || []).map(item => <UnstyledButton mt="md" style={{width: '100%'}} onClick={() => navigate(`/clients/${data?._id}?order=${item.id ?? item._id}`)}>
                        <JobKanban order={item} />
                    </UnstyledButton>)
                    }
                </Box>
            </Box>
        </Paper>
    </>
}
