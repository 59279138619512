import CompaniesList from "./companies/list";
import TeamsCreate from "./teams/create";
import TeamsList from "./teams/list";
import UsersCreate from "./users/create";
import UsersList from "./users/list";


const Users = {
    List: UsersList,
    Create: UsersCreate
}

const Teams = {
    List: TeamsList,
    Create: TeamsCreate
}

const Companies = {
    List: CompaniesList,
}



export { Companies, Users };
