import { Avatar, Button, Group, Menu, Text, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import * as React from 'react';

interface SimpleHeaderProps{
    title : string;
    subtitle? : string;
    right?: any;
    Icon?: any;
    button?: { title: string; onClick?: any, [key: string]: any, variant?: string };
    buttons?: { title: string; onClick?: any, [key: string]: any, variant?: string }[];
}

export default function SimpleHeader(props : SimpleHeaderProps){
    let {Icon, title, subtitle, button, right, buttons} = props;
    const isMobile = useMediaQuery('(max-width: 900px)');
    buttons = buttons || (button ? [button] : [])

    const btn = (button) => <Button
        variant={button.variant? button.variant : 'filled'}
        className='draw-button'
        size="sm"
        {...button}
    >{button.title}</Button>

    return <Group justify="apart" align="flex-start" mt="md" mb="md" style={{flexDirection: isMobile ? "column" : "row"}}>
        <Group style={{flex: 1}} align='flex-start'>
            {Icon && <Avatar color="orange"><Icon size="1.5rem" /></Avatar>}
            <div>
                <Title order={3} c="black">{title}</Title>
                {subtitle && <Text mt="xs" size="sm" c="#4a4a4a">{subtitle}</Text>}
            </div>
        </Group>
        <Group align='flex-start'>
            {right}
            {buttons.map(btn)}
        </Group>
    </Group>
}