import { ActionIcon, Group, Menu, Text, Title } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import {
    FaPaperPlane,
    FaPaperclip,
    FaSmile,
    FaTimes
} from "react-icons/fa";
import PdfThumb from "./pdf_thumb";
import FilePicker from "./file_picker";
import ConversationBox from "./conversations/conversation_box";
import EmojiPicker from "emoji-picker-react";
import InputField, { InputFieldTypes } from './input_field';
import { useRecorder } from "./audio_recorder";
import { notifications } from '@mantine/notifications';
import { useMediaQuery } from '@mantine/hooks';

export default function MessagesBox({ onSubmit, minRows = 1, maxRows = 4, messages = [], loading = false, allowMessage = true }){
    const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);
    const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [textMessage, setTextMessage] = useState<string>("");

    const recorder = useRecorder();
    const isMobile = useMediaQuery('(max-width: 900px)');

    const handleDrop = (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.items).map((item: any) =>
            item.getAsFile()
        );
        setUploadedFiles((uf) => [...uf, ...files]);
        setIsDraggingOver(false);
    };
    
    const handlePaste = (event) => {
        const wd: any = window;
        const clipboardData = event.clipboardData || wd.clipboardData;
        const items = clipboardData.items;
    
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.kind === "file") {
                const file = item.getAsFile();
                setUploadedFiles((uf) => [...uf, file]);
                setSelectedFile(file);
            }
        }
    };
    
    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDraggingOver(true);
    };
    
    const handleDragLeave = (event) => {
        event.preventDefault();
        setIsDraggingOver(false);
    };

    const handleSubmit = (params) => {
        onSubmit(params);
        setUploadedFiles([]);
        setTextMessage("");
    }

    useEffect(() => {
        window.addEventListener("paste", handlePaste);
        return () => {
            window.removeEventListener("paste", handlePaste);
        };
    }, []);

    useEffect(() => {
        let currFile = uploadedFiles.find((f) => f.name === selectedFile?.name);
        if (!currFile && uploadedFiles.length > 0) {
            setSelectedFile(uploadedFiles[0]);
        }else if(!currFile && selectedFile){
            setSelectedFile(null)
        }
        if(uploadedFiles.some(f => f.size > 5000000)){
          notifications.show({message: "Please, submit files less than 5mb", color: "red"})
          setUploadedFiles(uploadedFiles.filter(f => f.size <= 5000000))
        }
      }, [uploadedFiles, selectedFile]);

    const PrincipalInput = <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
        <Group p="md">
            {!isMobile && <Menu>
                <Menu.Target>
                    <ActionIcon color="gray" variant='light' >
                        <FaSmile />
                    </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                    <EmojiPicker
                        onEmojiClick={({ emoji }) => {
                            setTextMessage((tm) => `${tm}${emoji}`);
                        }}
                    />
                </Menu.Dropdown>
            </Menu>}
            <FilePicker onSelect={setUploadedFiles}>
            <ActionIcon color="gray"  variant='light' >
                <FaPaperclip />
            </ActionIcon>
            </FilePicker>
            
            <InputField
                style={{ flex: 1 }}
                fullWidth
                onKeyDown={(e) => {
                if (e.keyCode === 13 && !e.shiftKey) {
                    handleSubmit({text: textMessage, files: uploadedFiles});
                    e.preventDefault();
                }
                }}
                value={textMessage}
                fieldType={InputFieldTypes.TEXTAREA}
                minRows={minRows ?? 1}
                maxRows={maxRows ?? 4}
                onChange={({ body }) => setTextMessage(body ? body : "")}
                name="body"
                placeholder="Type your message"
                autoComplete="off"
            />
            <ActionIcon
              color="orange"
              size="xl"
              onClick={() => handleSubmit({text: textMessage, files: uploadedFiles})}
              variant='filled'
            >
              <FaPaperPlane />
            </ActionIcon>
        </Group>
    </div>

    const BoxMessages = <ConversationBox
        messages={messages}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
    />

    const UploadedFiles = <>
      {uploadedFiles.length > 0 && (
        <div className="file-preview">
          <Group>
              <ActionIcon
                  onClick={() => setUploadedFiles([])}
                  m="lg"
                  color="gray"
              ><FaTimes /></ActionIcon>
              <Title order={5} c="gray" style={{ flex: 1 }}>{selectedFile?.name}</Title>
          </Group>
          <div
            style={{
              flex: 1,
              display: "flex",
              overflow: "hidden",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {selectedFile &&
              (selectedFile.type === "application/pdf" ? (
                <PdfThumb pdfBlobUrl={URL.createObjectURL(selectedFile)} />
              ) : selectedFile.type.includes("image") ? (
                <div
                  style={{
                    width: 200,
                    height: 300,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    background: "#F4F4F4",
                    border: "1px solid #EFEFEF",
                    borderRadius: 10,
                    backgroundPosition: "center center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${URL.createObjectURL(
                      selectedFile
                    )})`,
                  }}
                />
              ) : (
                <div
                  style={{
                    width: 300,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: 200,
                    background: "#F4F4F4",
                    border: "1px solid #EFEFEF",
                    borderRadius: 10,
                  }}
                >
                  {selectedFile.type.includes("video") ? (
                    <video
                      controls
                      height="100%"
                      src={URL.createObjectURL(selectedFile)}
                    ></video>
                  ) : (
                    <Text ta="center" c="gray" size="xs">
                      {selectedFile.name}
                    </Text>
                  )}
                </div>
              ))}
          </div>
          <Group style={{ padding: "20px 120px" }}>
            <div style={{ flex: 1 }}>{PrincipalInput}</div>
          </Group>
          <div
            style={{
              display: "flex",
              paddingBottom: 20,
              overflow: "hidden",
              cursor: "pointer",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {uploadedFiles.map((file, i) => (
              <div
                onClick={() => setSelectedFile(file)}
                style={{
                  position: "relative",
                  background: "white",
                  margin: 5,
                  borderRadius: 5,
                  border:
                    file.name === selectedFile?.name
                      ? "2px solid #2470ac"
                      : "unset",
                }}
              >
                <ActionIcon
                  variant="light"
                  onClick={() =>
                    setUploadedFiles((uf) => uf.filter((item, j) => i !== j))
                  }
                  size="xs"
                  style={{ position: "absolute", top: 0, right: 0 }}
                >
                  <FaTimes />
                </ActionIcon>
                {file.type === "application/pdf" ? (
                  <PdfThumb
                    pdfBlobUrl={URL.createObjectURL(file)}
                    height={80}
                    width={60}
                  />
                ) : file.type.includes("image") ? (
                  <div
                    style={{
                      width: 60,
                      height: 80,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      background: "#F4F4F4",
                      border: "1px solid #EFEFEF",
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundImage: `url(${URL.createObjectURL(file)})`,
                    }}
                  />
                ) : (
                  <Text p="lg" size="xs">{file.name}</Text>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
    
    const FullComponent = <div
        style={{display: 'flex', flexDirection: 'column', height: '100%'}}
    >
        {UploadedFiles}
        {BoxMessages}
        {allowMessage && PrincipalInput}
    </div>

    return {
        UploadedFiles,
        PrincipalInput,
        FullComponent,
        BoxMessages,
    }
}