import { Divider, Group, Paper, Text, Title } from '@mantine/core';
import moment from 'moment';
import React from 'react';
import { useAuth } from '../contexts/auth.context';

export default function OverviewPage(){
    const { user: currentUser, role } = useAuth();

    return <>
        <Paper p="md" shadow='xs'>
            {[
                { title: "Usuário:", value: currentUser?.name },
                { title: "E-mail:", value: currentUser?.email },
                role && { title: "Empresa:", value: role?.company?.name },
                role && { title: "Celular:", value: role?.company?.phone },
                role && { title: "CPF/CNPJ:", value: role?.company?.register_number },
                role && { title: "Empresa:", value: role?.company?.name },
                role && { title: "Tipo da empresa:", value: role?.company?.type === "1" ? "Conta pessoal" : "Conta empresarial" },
                role && { title: "Quantidade de colaboradores:", value: role?.company?.employees || "N/A" },
                role && { title: "Data de registro:", value: moment(role?.company?.createdAt).format("DD/MM/YYYY") },
            ].filter(nn => nn).map(item => <>
                <Group grow mb="xl">
                    <Title order={4}>{item.title}</Title>
                    <Text c="orange">{item.value}</Text>
                </Group>
                <Divider mb="xl" />
            </>)}
        </Paper>
    </>
}
