import { api } from "./api"

export const GET_ALL_SUPPORTS = async () => {
    const {data} = await api.get("/supports")
    return data.supports || [];
}

export const GET_SUPPORT_DATA = async (supportId: string) => {
    const {data} = await api.get(`/supports/${supportId}`)
    return data;
}

export const DELETE_SUPPORT = async (id) => {
    const {data} = await api.delete(`/supports/${id}`)
    return data;
}

export const CREATE_SUPPORT = async (params : any) => {
    const {data} = await api.post(`/supports`, params)
    return data;
}

export const UPDATE_SUPPORT = async (id: string, params : any) => {
    const {data} = await api.patch(`/supports/${id}`, params)
    return data;
}

export const SEND_SUPPORT_MESSAGE = async (supportId, { text, files = [] }) => {
    const formData = new FormData();
    files.forEach(file => { formData.append('files', file) });
    if(text) formData.append("text", text);
    
    const {data} = await api.post(`/supports/${supportId}/message`, formData)
    return data;
}