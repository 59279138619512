import { Center } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React from "react";
import { Location, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/auth.context";

export default function Policy({ company = false }) {
  const { user } = useAuth();
  const location = useLocation();

  const isMobile = useMediaQuery("(max-width: 900px)");

  const state = location.state as { from: Location };
  const from = state ? state.from.pathname : "/";

  return !!user ? (
    <Navigate to="/" state={{ from }} />
  ) : (
    <Center
      style={{
        background: `linear-gradient(to top right, #7950f1, #7950f1, #7950f1)`,
      }}
    >
      <div
        style={{
          width: isMobile ? "90vw" : company ? "60vw" : "40vw",
          padding: isMobile ? "2rem 0" : "unset",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            marginTop: 0,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            alt="retainr"
            src={`/logo.png`}
            style={{ height: 220, marginBottom: -40 }}
          />
        </div>

        <h1 style={{ color: "#fff" }}>Política de Privacidade</h1>

        <h4
          style={{
            WebkitTextStrokeWidth: 0,
            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          <strong>Política de privacidade</strong>
        </h4>
        <p
          style={{
            WebkitTextStrokeWidth: 0,
            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          O site retainr é de propriedade da empresa Fridom, que é a controladora
          de seus dados pessoais.
        </p>
        <p
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          Nós adotamos esta Política de Privacidade, que determina como nós
          estamos processando as informações coletadas pelo site retainr e também
          explica por quais razões nós precisamos coletar dados pessoais sobre
          você. Portanto, você deve ler esta Política de Privacidade antes de
          usar o site retainr.
        </p>
        <p
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          Nós cuidamos dos seus dados pessoais e assumimos a responsabilidade de
          garantir a confidencialidade e segurança de suas informações pessiais.
        </p>
        <h4
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          <strong>Estes são as informações pessoais que coletamos:</strong>
        </h4>
        <p
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          Quando você visita o site retainr, nós aytumaticamente coletamos certas
          informações sobre seu dispositivo, incluindo informações sobre seu
          navegador, endereço IP, fuso horário e alguns dos cookies instalados
          no seu sispositivo. Além disso, quando você navega pelo Site, nós
          coletamos informações sobre as páginas individuais ou produtos que
          você visualiza, sobre quais sites ou termos de busca redirecionaram
          você para nosso Site, e sobre como você interage com o Site. Nós nos
          referimos a essas informações coletadas automaticamente como
          "Informações sobre o dispositivo". Além disso, nós podemos coletar
          dados pessoais que você fornecer (incluindo, mas não limitado a: Nome,
          Sobrenome, Endereço, informações de pagamento, etc) durante o processo
          de registro para poder cumprir o acordo.
        </p>
        <h4
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          <strong>Por que fazemos o processamento dos seus dados?</strong>
        </h4>
        <p
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          Nossa maior prioridade é a segurança dos dados pessoais dos usuários
          e, portanto, nós podemos processar apenas dados mínimos, apenas
          enquanto for absolutamente necessário para a manutenção do site.
          Informações coletadas automaticamente são usadas para identificar
          possíveis casos de abuso e estabelecer dados estatísticos sobre o uso
          do site. Esses dados estatísticos não pe agregada de outras formas que
          permitam a identificação de usuários específicos do sistema.
        </p>
        <p
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          Você pode visitar o site sem nos contar sobre quem você é ou relevar
          qualquer informação que possa ser usada por outra pessoa para
          identificar você individualmente. Se, apesar disso, você quiser
          utilizar algum dos recursos do site, ou quiser receber nossa
          newsletter, ou quiser conceder outros detalhes através do preencimento
          e envio de formulários, você poderá fornecer dados pessoais para nós,
          como seu email, nome, sobrenome, cidade de residência, organização e
          número de telefone. Você pode escolher não fornecer dados pessoais
          para nós, mas, dessa forma, talvez você não consiga usar alguns dos
          resursos do site. Por exemplo, você não vai conseguir receber nossa
          Newsletter ou entrar em contato conosco diretamente pelo nosso site.
          Usuários que não tenham certeza sobre quais informações pessoais são
          obrigatórias são convidados a entrarem em contato conosco pelo e-mail
          contato@fridom.com.br.
        </p>
        <h4
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          <strong>Seus direitos:</strong>
        </h4>
        <p
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          Se você morar na Europa, estes são os direitos garantidos quando aos
          seus dados pessoais:
        </p>
        <ul>
          <li
            style={{
              WebkitTextStrokeWidth: 0,

              boxSizing: "border-box",
              color: "#fff",
              fontFamily: "Roboto, sans-serif",
              fontSize: 14,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontVariantLigatures: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              listStylePosition: "inside",
              margin: "0px 0px 16px",
              orphans: 2,
              padding: 0,
              textAlign: "start",
              textDecorationColor: "initial",
              textDecorationStyle: "initial",
              textDecorationThickness: "initial",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: 2,
              wordSpacing: 0,
            }}
          >
            O direito de ser informado.
          </li>
          <li
            style={{
              WebkitTextStrokeWidth: 0,

              boxSizing: "border-box",
              color: "#fff",
              fontFamily: "Roboto, sans-serif",
              fontSize: 14,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontVariantLigatures: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              listStylePosition: "inside",
              margin: "0px 0px 16px",
              orphans: 2,
              padding: 0,
              textAlign: "start",
              textDecorationColor: "initial",
              textDecorationStyle: "initial",
              textDecorationThickness: "initial",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: 2,
              wordSpacing: 0,
            }}
          >
            O direito ao acesso.
          </li>
          <li
            style={{
              WebkitTextStrokeWidth: 0,

              boxSizing: "border-box",
              color: "#fff",
              fontFamily: "Roboto, sans-serif",
              fontSize: 14,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontVariantLigatures: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              listStylePosition: "inside",
              margin: "0px 0px 16px",
              orphans: 2,
              padding: 0,
              textAlign: "start",
              textDecorationColor: "initial",
              textDecorationStyle: "initial",
              textDecorationThickness: "initial",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: 2,
              wordSpacing: 0,
            }}
          >
            O direito à retificação.
          </li>
          <li
            style={{
              WebkitTextStrokeWidth: 0,

              boxSizing: "border-box",
              color: "#fff",
              fontFamily: "Roboto, sans-serif",
              fontSize: 14,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontVariantLigatures: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              listStylePosition: "inside",
              margin: "0px 0px 16px",
              orphans: 2,
              padding: 0,
              textAlign: "start",
              textDecorationColor: "initial",
              textDecorationStyle: "initial",
              textDecorationThickness: "initial",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: 2,
              wordSpacing: 0,
            }}
          >
            O direito de deletar.
          </li>
          <li
            style={{
              WebkitTextStrokeWidth: 0,

              boxSizing: "border-box",
              color: "#fff",
              fontFamily: "Roboto, sans-serif",
              fontSize: 14,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontVariantLigatures: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              listStylePosition: "inside",
              margin: "0px 0px 16px",
              orphans: 2,
              padding: 0,
              textAlign: "start",
              textDecorationColor: "initial",
              textDecorationStyle: "initial",
              textDecorationThickness: "initial",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: 2,
              wordSpacing: 0,
            }}
          >
            O direito de restringir o processamento.
          </li>
          <li
            style={{
              WebkitTextStrokeWidth: 0,

              boxSizing: "border-box",
              color: "#fff",
              fontFamily: "Roboto, sans-serif",
              fontSize: 14,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontVariantLigatures: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              listStylePosition: "inside",
              margin: "0px 0px 16px",
              orphans: 2,
              padding: 0,
              textAlign: "start",
              textDecorationColor: "initial",
              textDecorationStyle: "initial",
              textDecorationThickness: "initial",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: 2,
              wordSpacing: 0,
            }}
          >
            O direito da portabilidade de dados.
          </li>
          <li
            style={{
              WebkitTextStrokeWidth: 0,

              boxSizing: "border-box",
              color: "#fff",
              fontFamily: "Roboto, sans-serif",
              fontSize: 14,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontVariantLigatures: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              listStylePosition: "inside",
              margin: "0px 0px 16px",
              orphans: 2,
              padding: 0,
              textAlign: "start",
              textDecorationColor: "initial",
              textDecorationStyle: "initial",
              textDecorationThickness: "initial",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: 2,
              wordSpacing: 0,
            }}
          >
            O direito à objeção.
          </li>
          <li
            style={{
              WebkitTextStrokeWidth: 0,

              boxSizing: "border-box",
              color: "#fff",
              fontFamily: "Roboto, sans-serif",
              fontSize: 14,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontVariantLigatures: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              listStylePosition: "inside",
              margin: "0px 0px 16px",
              orphans: 2,
              padding: 0,
              textAlign: "start",
              textDecorationColor: "initial",
              textDecorationStyle: "initial",
              textDecorationThickness: "initial",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: 2,
              wordSpacing: 0,
            }}
          >
            Direitos em relação a tomadas de decisão automaticas e à perfilagem.
          </li>
        </ul>
        <p
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          Se você quiser exercitar esses direitos, por favor entre em contato
          conosco usando os dados de contato abaixo.
        </p>
        <p
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          Adicionalmente, se você mora na Europa, nós afirmamos que estamos
          processando suas informações com a finalidade de cumprir contratos que
          possamos ter firmado com você (por exemplo, se você fizer uma compra
          no nosso Site), ou para excercer os interesses legítimos da nossa
          empresa listados acima. Além disso, por favor saiba que suas
          informações poder ser transferidas para fora da Europa, incluindo para
          o Canadá e os Estados Unidos da América.
        </p>
        <h4
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          <strong>Links para outros sites:</strong>
        </h4>
        <p
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          Nosso site pode conter links para outros sites que não são controlados
          por nós e/ou não são de nossa propriedade. Por favor esteja ciente de
          que nós não somos responsáveis pelas políticas de privacidade de tais
          sites e organizações terceiras. Nós incentivamos você a estar ciente
          de quando sair do nosso site, e também incentivamos você a ler a
          política de privacidade de cada um dos sites que podem coletar suas
          informações pessoais.
        </p>
        <h4
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          <strong>Segurança das informações:</strong>
        </h4>
        <p
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          Nós garantimos que as informações que você fornece estão em servidores
          e computadores armazenados em ambientes seguros e controlados,
          protegidos de acessos, usos e divulgações não-autorizadas. Nós matemos
          medidas de segurança administrativas, técnicas e físicas razoáveis,
          com finalidade de proteger os dados pessoais sob nossa custódia de de
          acessos, usos, modificações e divulgações não-autorizadas. Apesar
          disso, nenhuma transmissão de dados pela Internet ou por sistemas sem
          fio pode ser garantida.
        </p>
        <h4
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          <strong>Declaração legal:</strong>
        </h4>
        <p
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          Nós vamos divulgar qualquer informação que coletarmos, usarmos ou
          recebermos caso tal divulgação seja solicitada ou permitida por lei,
          de forma a cumprir intimações ou processos judiciais similares, e
          também quando considerarmos em boa fé que a divulgação é necessária
          para a proteção de nossos direitos, para a proteção da segurança de
          outros, para investigações de fraude ou para responder a uma
          solicitação do governo.
        </p>
        <h4
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            letterSpacing: "normal",
            margin: "0px 0px 16px",
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          <strong>Informações de contato:</strong>
        </h4>
        <p
          style={{
            WebkitTextStrokeWidth: 0,

            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Roboto, sans-serif",
            fontSize: 14,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontVariantLigatures: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            margin: 0,
            orphans: 2,
            padding: 0,
            textAlign: "start",
            textDecorationColor: "initial",
            textDecorationStyle: "initial",
            textDecorationThickness: "initial",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: 2,
            wordSpacing: 0,
          }}
        >
          Se você quiser entrar em contato conosco para saber mais sobre esta
          Política de Privacidade, ou quiser acessar quaisquer informações
          relativas aos seus direitos individuais e às suas Informações
          Pessoais, você poderá enviar um e-mail para o endereço
          contato@fridom.com.br.
        </p>

        <br />
      </div>
    </Center>
  );
}
